import { useForgottenUserPassword } from '@api/users';
import { useForm } from '@mantine/form';
import { Link } from 'react-router-dom';

import { Paper, TextInput, Button, Title } from '@mantine/core';
import styles from './Login.module.scss';

const ForgottenPassword = () => {
  const form = useForm({
    initialValues: { email: '' },

    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Correo Inválido'),
    },
  });

  const { mutate: ForgottenUserPassword, isLoading } = useForgottenUserPassword();

  const handleSubmit = async (e: any) => {
    ForgottenUserPassword(e);
  };
  return (
    <div className={styles.wrapper}>
      <Paper className={styles.form} radius={0} p={30}>
        <Title order={2} className={styles.title} ta="center" mt="md" mb={50}>
          Recuperar contraseña
        </Title>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            required
            label="E-mail"
            placeholder="hola@tufacturador.cl"
            size="md"
            {...form.getInputProps('email')}
          />
          <Button type="submit" fullWidth mt="xl" size="md" disabled={isLoading}>
            Recuperar
          </Button>
          <div className={styles.linkContainer}>
            <Link to="/login" className={styles.link}>
              Iniciar sesión
            </Link>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default ForgottenPassword;
