import { Grid, rem, Container, Text } from '@mantine/core';
import { IconFeather, IconPhotoScan, IconBuildingBank } from '@tabler/icons-react';
import ConfigurationCard from './ConfigurationCard';
import { useCredential } from '@api/credentials';
import { useAuth } from '@providers/auth';

function Index() {
  const { data: credential, isLoading: isCredentialLoading } = useCredential();
  const { session } = useAuth();
  const logo = session.activeAccount?.logo;
  const skeletonDisplay = isCredentialLoading;
  const iconStyle = {
    color: 'var(--mantine-primary-color-filled)',
    width: rem(80),
    height: rem(80),
  };

  return (
    <Container fluid>
      <Text fw={700} fz={28}>
        Configuración
      </Text>
      <Container>
        <Grid py={30}>
          <ConfigurationCard
            title="Firma Electrónica"
            link="/configuracion/firma-electronica"
            icon={<IconFeather style={iconStyle} />}
            state={credential?.data !== undefined ? 'success' : 'error'}
            skeletonDisplay={skeletonDisplay}
          />
          <ConfigurationCard
            title="Logo"
            link="/configuracion/logo"
            icon={<IconPhotoScan style={iconStyle} />}
            state={logo === undefined || '' || null ? 'error' : 'success'}
            skeletonDisplay={skeletonDisplay}
          />
          <ConfigurationCard
            title="Información Bancaria"
            link="/configuracion/informacion-bancaria"
            icon={<IconBuildingBank style={iconStyle} />}
            state={session.activeAccount?.banking_details ? 'success' : 'error'}
            skeletonDisplay={skeletonDisplay}
          />
        </Grid>
      </Container>
    </Container>
  );
}

export default Index;
