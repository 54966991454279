import { useState, useEffect } from 'react';
import {
  Stack,
  Group,
  TextInput,
  Paper,
  Text,
  Button,
  Grid,
  Select,
  NumberInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import styles from '@pages/authenticated/documents/Create.module.scss';
import { CHARGEKINDOPTIONS } from '@constants';
import { Reference } from '@types';

function Charges({ form }: { form: UseFormReturnType<any> }) {
  const initialChargesAmount =
    form.values.document.charges_attributes.filter(
      (chargeInstance: Reference) => !chargeInstance._destroy,
    ).length || 1;

  const [chargesAmount, setChargesAmount] = useState(initialChargesAmount);
  const charges = Array.from({ length: chargesAmount }, (_, index) => {
    if (!form.values.document.charges_attributes[index]._destroy)
      return <Line key={index} index={index} form={form} />;
  });
  const handleAddCharge = () => {
    form.insertListItem('document.charges_attributes', {
      kind: '',
      description: '',
      charge: '',
      net_amount: '',
      exempt_amount: '',
      tax_amount: '',
    });
    setChargesAmount((prevValue: number) => prevValue + 1);
  };

  const handleRemoveCharge = () => {
    if (form.values.document.charges_attributes[chargesAmount - 1].id) {
      form.setFieldValue(`document.charges_attributes.${chargesAmount - 1}._destroy`, true);
    } else {
      form.removeListItem('document.charges_attributes', chargesAmount - 1);
    }
    setChargesAmount((prevValue: number) => prevValue - 1);
  };

  useEffect(() => {
    setChargesAmount(form.values.document.charges_attributes.length);
  }, [form.values.document.charges_attributes]);

  return (
    <Paper shadow="xl" p="lg">
      <Text fw={700} mb={10} fz={20}>
        Cargos/Comisiones
      </Text>
      <Stack>{charges}</Stack>
      <Group justify="center" mt={10}>
        <Button className={styles.addBtn} onClick={handleAddCharge}>
          <IconPlus />
        </Button>
        {canDeleteLine(chargesAmount) && (
          <Button className={styles.addBtn} onClick={handleRemoveCharge} color="red">
            <IconMinus />
          </Button>
        )}
      </Group>
    </Paper>
  );
}

function Line({ index, form }: { index: number; form: UseFormReturnType<any> }) {
  return (
    <Grid>
      <Grid.Col span={{ base: 12, md: 2 }}>
        <Select
          label="Tipo"
          placeholder="Tipo"
          searchable
          data={CHARGEKINDOPTIONS}
          {...form.getInputProps(`document.charges_attributes.${index}.kind`)}
          value={form.values.document.charges_attributes[index].kind}
          onChange={(value) =>
            form.setFieldValue(`document.charges_attributes.${index}.kind`, value)
          }
        />
      </Grid.Col>
      <Grid.Col span={{ base: 11.4, md: 4 }}>
        <TextInput
          withAsterisk
          label="Glosa"
          placeholder="Glosa"
          {...form.getInputProps(`document.charges_attributes.${index}.description`)}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 3 }}>
        <NumberInput
          withAsterisk
          label="Valor Comisión Neto"
          placeholder="Valor Comisión Neto"
          allowDecimal={false}
          decimalSeparator=","
          thousandSeparator="."
          {...form.getInputProps(`document.charges_attributes.${index}.net_amount`)}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 3 }}>
        <NumberInput
          withAsterisk
          label="Valor Comisión Exento"
          placeholder="Valor Comisión Exento"
          allowDecimal={false}
          decimalSeparator=","
          thousandSeparator="."
          {...form.getInputProps(`document.charges_attributes.${index}.exempt_amount`)}
        />
      </Grid.Col>
    </Grid>
  );
}

function canDeleteLine(referenceAmount: number) {
  return referenceAmount > 0;
}

export default Charges;
