import { Grid, Text } from '@mantine/core';
import { useAuth } from '@providers/auth';
import styles from './DrawerPrevisualization.module.scss';
import { CommonHash, Account } from '@types';
import { transformDTETypeForLabel } from '@pages/authenticated/documents/form/formUtils';

function Header({ form, account }: { form: CommonHash; account?: Account }) {
  const { session } = useAuth();
  const logoUrl = session.activeAccount?.logo;
  const accountActivity = account?.activities.find(
    (activity) => activity.sii_code === form.values.account?.account_activity,
  );
  return (
    <Grid>
      <Grid.Col span={4} className={styles.col}>
        {logoUrl && (
          <>
            <img src={logoUrl} alt="logo" className={styles.logoContainer} />
            <div></div>
          </>
        )}
      </Grid.Col>
      <Grid.Col span={4} className={styles.col}>
        <Text fw={800}>{account?.legal_name}</Text>
        <Text fz={13} truncate="end">
          {accountActivity?.name || ''}
        </Text>
        <Text fz={13}>{form.values.account.account_address}</Text>
      </Grid.Col>
      <Grid.Col span={4} className={styles.col}>
        <div className={styles.redContainer}>
          <Text fw={400} fz={13}>
            Rut {account?.tax_id}
          </Text>
          <Text fw={700} fz={13}>
            {transformDTETypeForLabel(form.values.document.type)}
          </Text>
          <Text fz={13}>N° folio no asignado</Text>
        </div>
      </Grid.Col>
    </Grid>
  );
}

export default Header;
