import { Modal } from '@mantine/core';
import { useDocument } from '@api/documents';
import styles from './ModalPrevisualization.module.scss';
import Header from './Header';
import Receiver from './Receiver';
import References from './References';
import Lines from './Lines';
import Totals from './Totals';
import Packages from './Packages';
import Transport from './Transport';
import Charges from './Charges';
import { Transport as TransportType } from '@types';

interface CompProps {
  opened: boolean;
  documentUuid: string;
  close: () => void;
}

function DocumentPrevisualizationModal({ opened, close, documentUuid }: CompProps) {
  const { data: document } = useDocument(documentUuid);
  if (!document) {
    return null;
  }
  return (
    <Modal.Root size="lg" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content className={styles.modalContent}>
        <Modal.Header>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Header document={document.data} />
          <Receiver document={document.data} />
          {(document.data.references.length as number) > 0 && (
            <References document={document.data} />
          )}
          {document.data.transport?.total_packages && <Packages document={document.data} />}
          <Lines document={document.data} />
          {document.data.charges !== undefined && document.data.charges.length > 0 && (
            <Charges document={document.data} />
          )}
          {showTransport(document.data.transport) && (
            <Transport transport={document.data.transport as TransportType} />
          )}
          <Totals document={document.data} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

function showTransport(transport?: TransportType) {
  return (
    transport?.license_plate ||
    transport?.driver_company_tax_id ||
    transport?.driver_tax_id ||
    transport?.driver_name
  );
}
export default DocumentPrevisualizationModal;
