import request from '@utils/axios-utils';
import { useQuery } from 'react-query';
import { ApiList, Country } from '@types';
import { useAuth } from '@providers/auth';

export function useCountries() {
  const { session } = useAuth();
  return useQuery<ApiList<Country>>(
    ['countries'],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/countries`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}
