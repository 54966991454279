import { useState, useEffect } from 'react';
import { Grid, Text, Paper, Select, Group, Button, NumberInput } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { UseFormReturnType } from '@mantine/form';
import { Package as PackageType } from '@types';
import styles from '@pages/authenticated/documents/Create.module.scss';
import {
  MEASUREMENTUNITOPTIONS,
  EXPORTATIONSALESMODEOPTIONS,
  EXPORTATIONSALESCLAUSEOPTIONS,
  TRANSPORTROUTES,
} from '@constants';
import { usePorts } from '@api/ports';
import Packages from './Packages';
import { useCountries } from '@api/countries';

function Customs({ form }: { form: UseFormReturnType<any> }) {
  const { data: ports } = usePorts();
  const { data: countries } = useCountries();
  const transformedCountries = countries?.data.map((country) => {
    return {
      label: country.name,
      value: String(country.id),
    };
  });
  const transformedPorts =
    ports?.data.map((port) => ({
      value: String(port.id),
      label: port.name,
    })) || [];
  const initialPackagesAmount =
    form.values.document.transport_attributes.packages_attributes.filter(
      (packageInstance: PackageType) => packageInstance._destroy,
    ).length;

  const [packagesAmount, setPackagesAmount] = useState(initialPackagesAmount);

  useEffect(() => {
    setPackagesAmount(form.values.document.transport_attributes.packages_attributes.length);
  }, [form.values.document.transport_attributes.packages_attributes]);

  const handleAddPackage = () => {
    form.insertListItem('document.transport_attributes.packages_attributes', {
      id: '',
      code: '',
      quantity: '',
      brand: '',
      container_id: '',
      seal: '',
      issuer_seal: '',
      _destroy: false,
    });
    setPackagesAmount((prevValue: number) => prevValue + 1);
  };

  return (
    <>
      <Paper shadow="xl" p="lg">
        <Text fw={700} mb={10} fz={20}>
          Aduana
        </Text>
        <Grid>
          <Grid.Col span={{ base: 12, md: 2 }}>
            <Select
              withAsterisk
              label="Modalidad de Venta"
              placeholder="Modalidad de Venta"
              data={EXPORTATIONSALESMODEOPTIONS}
              {...form.getInputProps('document.transport_attributes.exportation_sales_mode')}
              value={String(form.values.document.transport_attributes.exportation_sales_mode)}
              onChange={(value) =>
                form.setFieldValue('document.transport_attributes.exportation_sales_mode', value)
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3 }}>
            <Select
              label="Cláusula de venta Exportación"
              placeholder="Cláusula de venta Exportación"
              data={EXPORTATIONSALESCLAUSEOPTIONS}
              {...form.getInputProps('document.transport_attributes.exportation_sales_clause')}
              value={form.values.document.transport_attributes.exportation_sales_clause}
              onChange={(value) =>
                form.setFieldValue('document.transport_attributes.exportation_sales_clause', value)
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3 }}>
            <NumberInput
              withAsterisk
              label="Total clausula de venta de exportación"
              placeholder="Total clausula de venta de exportación"
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              {...form.getInputProps(
                'document.transport_attributes.exportation_sales_clause_amount',
              )}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 2 }}>
            <Select
              label="Vía de transporte"
              placeholder="Vía de transporte"
              data={TRANSPORTROUTES}
              {...form.getInputProps('document.transport_attributes.transport_route')}
              value={String(form.values.document.transport_attributes.transport_route)}
              onChange={(value) =>
                form.setFieldValue('document.transport_attributes.transport_route', value)
              }
            />
          </Grid.Col>
          {/* <Grid.Col span={{ base: 12, md: 2 }}>
            <TextInput
              label="Nombre de Transporte"
              placeholder="Nombre de Transporte"
              {...form.getInputProps('document.transport_attributes.carrier_vessel')}
            />
          </Grid.Col> */}
          {/* <Grid.Col span={{ base: 12, md: 2 }}>
            <TextInput
              label="RUT Cía. Transportadora"
              placeholder="RUT Cía. Transportadora"
              {...form.getInputProps('document.transport_attributes.carrier_company_tax_id')}
              onBlur={(e) => onBlurTax(e.target.value)}
            />
          </Grid.Col> */}
          {/* <Grid.Col span={{ base: 12, md: 2 }}>
            <TextInput
              label="Nombre Cía. Transportadora"
              placeholder="Nombre Cía. Transportadora"
              {...form.getInputProps('document.transport_attributes.carrier_company_name')}
            />
          </Grid.Col> */}
          {/* <Grid.Col span={{ base: 12, md: 2 }}>
            <TextInput
              label="Ident. Adicional Cía. Transportadora"
              placeholder="Ident. Adicional Cía. Transportadora"
              {...form.getInputProps(
                'document.transport_attributes.carrier_company_additional_info',
              )}
            />
          </Grid.Col> */}
          {/* <Grid.Col span={{ base: 12, md: 1 }}>
            <TextInput
              label="Booking"
              placeholder="Booking"
              {...form.getInputProps('document.transport_attributes.booking_number')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 1 }}>
            <TextInput
              label="Operador"
              placeholder="Operador"
              {...form.getInputProps('document.transport_attributes.operator_code')}
            />
          </Grid.Col> */}
          <Grid.Col span={{ base: 12, md: 2 }}>
            <Select
              searchable
              label="Puerto de embarque"
              placeholder="Puerto de embarque"
              data={transformedPorts}
              {...form.getInputProps('document.transport_attributes.shipping_port_id')}
              value={String(form.values.document.transport_attributes.shipping_port_id)}
              onChange={(value) =>
                form.setFieldValue('document.transport_attributes.shipping_port_id', value)
              }
            />
          </Grid.Col>
          {/* <Grid.Col span={{ base: 12, md: 2 }}>
            <TextInput
              label="Ident. Adicional Puerto de Embarque"
              placeholder="Ident. Adicional Puerto de Embarque"
              {...form.getInputProps('document.transport_attributes.shipping_port_additional_info')}
            />
          </Grid.Col> */}
          <Grid.Col span={{ base: 12, md: 2 }}>
            <Select
              searchable
              label="Puerto desembarque"
              placeholder="Puerto desembarque"
              data={transformedPorts}
              {...form.getInputProps('document.transport_attributes.landing_port_id')}
              value={String(form.values.document.transport_attributes.landing_port_id)}
              onChange={(value) =>
                form.setFieldValue('document.transport_attributes.landing_port_id', value)
              }
            />
          </Grid.Col>
          {/* <Grid.Col span={{ base: 12, md: 2 }}>
            <TextInput
              label="Ident. Adicional Puerto de Desembarque"
              placeholder="Ident. Adicional Puerto de Desembarque"
              {...form.getInputProps('document.transport_attributes.landing_port_additional_info')}
            />
          </Grid.Col> */}
          <Grid.Col span={{ base: 12, md: 2 }}>
            <Select
              withAsterisk
              searchable
              label="País Receptor"
              placeholder="País Receptor"
              data={transformedCountries}
              {...form.getInputProps('document.transport_attributes.receiver_country_id')}
              value={String(form.values.document.transport_attributes.receiver_country_id)}
              onChange={(value) =>
                form.setFieldValue('document.transport_attributes.receiver_country_id', value)
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 2 }}>
            <Select
              searchable
              label="País Destino"
              placeholder="País Destino"
              data={transformedCountries}
              {...form.getInputProps('document.transport_attributes.destination_country_id')}
              value={String(form.values.document.transport_attributes.destination_country_id)}
              onChange={(value) =>
                form.setFieldValue('document.transport_attributes.destination_country_id', value)
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 2 }}>
            <NumberInput
              label="Tara"
              placeholder="Tara"
              allowDecimal={false}
              thousandSeparator="."
              decimalSeparator=","
              {...form.getInputProps('document.transport_attributes.tare')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <Select
              label="Unidad de medida de la Tara"
              placeholder="Unidad de medida de la Tara"
              data={MEASUREMENTUNITOPTIONS}
              {...form.getInputProps('document.transport_attributes.tare_unit')}
              value={String(form.values.document.transport_attributes.tare_unit)}
              onChange={(value) =>
                form.setFieldValue('document.transport_attributes.tare_unit', value)
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 2 }}>
            <NumberInput
              withAsterisk
              label="Peso Bruto"
              placeholder="Peso Bruto"
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              {...form.getInputProps('document.transport_attributes.gross_weight')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3 }}>
            <Select
              label="Unidad de medida del Peso Bruto"
              placeholder="Unidad de medida del Peso Bruto"
              data={MEASUREMENTUNITOPTIONS}
              {...form.getInputProps('document.transport_attributes.gross_weight_unit')}
              value={String(form.values.document.transport_attributes.gross_weight_unit)}
              onChange={(value) =>
                form.setFieldValue('document.transport_attributes.gross_weight_unit', value)
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 2 }}>
            <NumberInput
              withAsterisk
              label="Peso Neto"
              placeholder="Peso Neto"
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              {...form.getInputProps('document.transport_attributes.net_weight')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3 }}>
            <Select
              label="Unidad de medida del Peso Neto"
              placeholder="Unidad de medida del Peso Neto"
              data={MEASUREMENTUNITOPTIONS}
              {...form.getInputProps('document.transport_attributes.net_weight_unit')}
              value={String(form.values.document.transport_attributes.net_weight_unit)}
              onChange={(value) =>
                form.setFieldValue('document.transport_attributes.net_weight_unit', value)
              }
            />
          </Grid.Col>
          {/* <Grid.Col span={{ base: 12, md: 1 }}>
            <TextInput
              label="Total Ítems"
              placeholder="Total Ítems"
              {...form.getInputProps('document.transport_attributes.total_items')}
            />
          </Grid.Col> */}
          <Grid.Col span={{ base: 12, md: 2 }}>
            <NumberInput
              label="Total Bultos"
              placeholder="Total Bultos"
              allowDecimal={false}
              {...form.getInputProps('document.transport_attributes.total_packages')}
              onChange={(value) => {
                form.setFieldValue('document.transport_attributes.total_packages', value);
                if ((value as number) > 1 && packagesAmount == 0) {
                  handleAddPackage();
                }
              }}
            />
          </Grid.Col>
        </Grid>
        {packagesAmount == 0 && (
          <Group justify="center" mt={10}>
            <Button className={styles.addBtn} onClick={handleAddPackage}>
              Bultos
              <IconPlus style={{ marginLeft: '5px' }} />
            </Button>
          </Group>
        )}
      </Paper>
      {packagesAmount > 0 && (
        <Packages
          form={form}
          packagesAmount={packagesAmount}
          setPackagesAmount={setPackagesAmount}
        />
      )}
    </>
  );
}

export default Customs;
