import { Stack, Group, Text, Paper, NumberFormatter, NumberInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import styles from '@pages/authenticated/documents/Create.module.scss';
import { calculateTotals, exemptType, exportType, receiptType } from './formUtils';
import { useMemo } from 'react';

function Totals({ form }: { form: UseFormReturnType<any> }) {
  const {
    exemptAmount,
    netAmount,
    taxAmount,
    totalAmount,
    chargesNetAmount,
    chargesExemptAmount,
    chargesTaxAmount,
  } = useMemo(() => calculateTotals(form), [form.values.document.lines_attributes]);

  return (
    <div className={styles.totalsWrapper}>
      <Paper shadow="xl" p="lg" className={styles.totalsContainer}>
        <Text fw={700} mb={10} fz={20}>
          Totales
        </Text>
        <Stack gap={5}>
          {form.values.document.type !== 'ElectronicSettlement' &&
            !exemptType(form.values.document.type) &&
            !exportType(form.values.document.type) && <GlobalDiscount form={form} />}
          {!exemptType(form.values.document.type) && !exportType(form.values.document.type) && (
            <TotalField name="Monto Neto" value={netAmount as number} />
          )}
          <TotalField name="Monto exento" value={exemptAmount as number} />
          {!exemptType(form.values.document.type) && !exportType(form.values.document.type) && (
            <>
              <TotalField name="IVA" value={19} tax={true} />
              <TotalField name="Total IVA" value={taxAmount as number} />
            </>
          )}
          {form.values.document.withholding_tax_percentage !== 0 && (
            <TotalField name="IVA Retenido" value={taxAmount as number} />
          )}
          {form.values.document.charges_attributes.length > 0 && (
            <>
              <TotalField name="Neto Comisión" value={chargesNetAmount as number} />
              <TotalField name="Exento Comisión" value={chargesExemptAmount as number} />
              <TotalField name="IVA Comisión" value={chargesTaxAmount} />
            </>
          )}
          <TotalField name="Total" value={totalAmount as number} />
        </Stack>
      </Paper>
    </div>
  );
}

function TotalField({ name, value, tax }: { name: string; value: number; tax?: boolean }) {
  return (
    <Group justify="space-between" gap={4}>
      <Text fw={600}>{name}:</Text>
      {tax && <Text>{value}%</Text>}
      {!tax && (
        <NumberFormatter prefix="$ " value={value} thousandSeparator="." decimalSeparator="," />
      )}
    </Group>
  );
}

function GlobalDiscount({ form }: { form: UseFormReturnType<any> }) {
  const globalDiscountPercentage = form.values.document.global_discounts_attributes[0].value;
  const { preTotalAmount, preNetAmount, globalDiscountAmount } = calculateTotals(form);
  const subTotal = receiptType(form.values.document.type) ? preTotalAmount : preNetAmount;
  const globalDiscountPercentagePresent =
    globalDiscountPercentage !== '0' && globalDiscountPercentage !== '' && subTotal !== 0;
  return (
    <>
      {globalDiscountPercentagePresent && <TotalField name="Subtotal" value={subTotal} />}
      <Group justify="space-between">
        <Group>
          <Text fw={600}>Dcto Global:</Text>
          <NumberInput
            size={'xs'}
            w={60}
            suffix="%"
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            {...form.getInputProps('document.global_discounts_attributes.0.value')}
          />
        </Group>
        {globalDiscountPercentagePresent && (
          <>
            <Text fw={600}>Monto:</Text>
            <NumberFormatter
              prefix="$ "
              value={globalDiscountAmount}
              thousandSeparator="."
              decimalSeparator=","
            />
          </>
        )}
      </Group>
    </>
  );
}

export default Totals;
