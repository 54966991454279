/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAuth } from '@providers/auth';
import { useQueryClient } from 'react-query';
import Notification from '@components/notification/notification';

let API_URL = 'http://localhost:3000/api/v1';
if (import.meta.env.VITE_DEPLOY_ENV === 'main') {
  // API_URL = 'https://api.tufacturador.cl/api/v1';
  API_URL = 'https://api.tufacturador.plaglabs.com/api/v1';
} else if (import.meta.env.VITE_DEPLOY_ENV === 'cert') {
  API_URL = 'https://facturador.plaglabs.com/api/v1';
}
const api = axios.create({ baseURL: API_URL });

const request = async ({ authorizationToken, ...options }: any) => {
  const apiConfig = {
    headers: {
      ...(authorizationToken && { Authorization: `Bearer ${authorizationToken}` }),
    },
    ...options,
  };

  return api(apiConfig);
};

export default request;

const AxiosInterceptor = ({ children }: { children: any }) => {
  const { session, setSession } = useAuth();
  const queryClient = useQueryClient();

  const [isSet, setIsSet] = useState(false);
  useEffect(() => {
    const resInterceptor = (response: any) => {
      return response;
    };

    const errInterceptor = (error: any) => {
      if (!axios.isCancel(error) && !error.response) {
        console.error('Ha ocurrido un problema, revisa tu conexión a internet');
      } else if (error.response?.status === 401) {
        const { status } = session;
        if (error.config.url === '/logout') return;
        if (status !== 'loggedIn') {
          setSession({ status: 'loggedOut' });
          Notification({ type: 'error', message: 'Tienes que volver a iniciar sesión' });
          queryClient.invalidateQueries(['user']);
        }
      }
      return Promise.reject(error);
    };

    const interceptor = api.interceptors.response.use(resInterceptor, errInterceptor);
    setIsSet(true);
    return () => api.interceptors.response.eject(interceptor);
  }, []);

  return isSet && children;
};

export { AxiosInterceptor };
