import { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useFileImports } from '@api/fileImports';
import { Title, Pagination, Group, Button } from '@mantine/core';
import CreateFileImportModal from './create';
import Table from './table';

const FileImportIndex = () => {
  const [activePage, setPage] = useState(1);
  const [openedCreateModal, { open: openCreateModal, close: closeCreateModal }] =
    useDisclosure(false);

  const { data: fileImports, isFetching } = useFileImports(activePage);

  return (
    <>
      <Title order={1}>Carga Masiva DTEs</Title>
      <Group justify="end" mt={10} mb={20}>
        <Button className="green-button" onClick={openCreateModal}>
          Cargar Archivo
        </Button>
      </Group>
      <Table data={fileImports?.data || []} isFetching={isFetching} />
      <div className="d-flex justify-content-center">
        <Pagination
          total={fileImports?.meta.pages || 1}
          value={activePage}
          onChange={setPage}
          mt="sm"
          pb={40}
        />
      </div>
      <CreateFileImportModal opened={openedCreateModal} close={closeCreateModal} />
    </>
  );
};

export default FileImportIndex;
