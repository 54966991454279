import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App.tsx';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import './index.scss';

if (import.meta.env.VITE_DEPLOY_ENV === 'main' || import.meta.env.VITE_DEPLOY_ENV === 'cert') {
  Sentry.init({
    dsn: 'https://b4285a225ce00ee3f29e02872d458ec0@o256157.ingest.us.sentry.io/4506852867833856',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    tracesSampleRate: 1.0,
  });
}
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
