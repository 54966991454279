import { Text, Stack } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { calculateTotals, exemptType, exportType } from '../../form/formUtils';
import styles from './DrawerPrevisualization.module.scss';
import { useMemo } from 'react';

function Totals({ form }: { form: UseFormReturnType<any> }) {
  const {
    exemptAmount,
    netAmount,
    taxAmount,
    totalAmount,
    chargesNetAmount,
    chargesExemptAmount,
    chargesTaxAmount,
    globalDiscountAmount,
  } = useMemo(() => calculateTotals(form), [form.values.document.lines_attributes]);
  return (
    <Stack mt={40} gap="0" align="flex-end">
      <div className={styles.totalBox}>
        {globalDiscountAmount !== 0 && <TotalLine label="Descuento" value={globalDiscountAmount} />}
        {!exemptType(form.values.document.type) && !exportType(form.values.document.type) && (
          <TotalLine label="Neto" value={netAmount} />
        )}
        <TotalLine label="Exento" value={exemptAmount} />
        {!exemptType(form.values.document.type) && !exportType(form.values.document.type) && (
          <TotalLine label="IVA" value={taxAmount} />
        )}
        {form.values.document.charges_attributes.length > 0 && (
          <>
            <TotalLine label="Neto Comisión" value={chargesNetAmount as number} />
            <TotalLine label="Exento Comisión" value={chargesExemptAmount as number} />
            <TotalLine label="IVA Comisión" value={chargesTaxAmount} />
          </>
        )}
        <TotalLine label="Total" value={totalAmount} />
      </div>
    </Stack>
  );
}

export default Totals;

function TotalLine({ label, value }: { label: string; value: number }) {
  return (
    <div className={styles.totalLine}>
      <div className={styles.dFlex}>
        <Text className={styles.totalLabel}>{label}</Text>
      </div>
      <div className={styles.justifyEnd}>
        <Text fz={14}>${value.toLocaleString('es-CL')}</Text>
      </div>
    </div>
  );
}
