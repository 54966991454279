import {
  Modal,
  Stack,
  Text,
  Group,
  Button,
  List,
  Anchor,
  TextInput,
  PasswordInput,
  Input,
} from '@mantine/core';
import { validate, format } from 'rut.js';
import { IconInfoCircle } from '@tabler/icons-react';
import DropzoneComponent from '@components/Dropzone';
import { useAddCredential } from '@api/credentials';
import { useForm, isNotEmpty, matchesField } from '@mantine/form';

interface CompProps {
  opened: boolean;
  close: () => void;
}
interface FormProps {
  tax_id: string;
  cert_password: string;
  confirm_cert_password: string;
  file: File | null;
}
function ListComponent() {
  const listInfo = [
    {
      value: 'Firma electrónica simple o avanzada provista por una entidad certificada.',
      urlText: 'Ver proveedores autorizados',
      url: 'https://www.sii.cl/servicios_online/1039-certificado_digital-1182.html',
    },
    {
      value: 'La firma no debe estar expirada (se compran por cierta cantidad de años).',
      urlText: '',
      url: '',
    },
    {
      value: 'La firma debe ser de tu propiedad.',
      urlText: '',
      url: '',
    },
    {
      value: 'Debes ser un representante legal de la empresa',
      urlText: '',
      url: '',
    },
    {
      value: 'Debes poseer la contraseña asociada a la firma',
      urlText: '',
      url: '',
    },
    {
      value: 'Debes haber registrado la firma en el portal del SII.',
      urlText: '¿Cómo se registra?',
      url: 'https://www.sii.cl/factura_electronica/factura_sii/guia_cert_dig_chrome45.htm',
    },
  ];
  const items = listInfo.map((info, index) => (
    <List.Item c="dimmed" key={index}>
      {info.value}
      {info.url && (
        <Anchor href={info.url} target="_blank" c={'var(--mantine-primary-color-filled'}>
          {' '}
          {info.urlText}
        </Anchor>
      )}
    </List.Item>
  ));
  return <List ps={20}>{items}</List>;
}

function UploadCredentialModal({ opened, close }: CompProps) {
  const { mutate: AddCredential } = useAddCredential();
  // https://pki-tutorial.readthedocs.io/en/latest/mime.html#mime-types cuando implementamos más tipos de certs
  const allowExtensions = ['application/x-pkcs12'];

  const form = useForm<FormProps>({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      tax_id: '',
      cert_password: '',
      confirm_cert_password: '',
      file: null,
    },

    validate: {
      tax_id: (value) => (validate(value) ? null : 'El rut es inválido'),
      cert_password: isNotEmpty('Contraseña no puede ser vacía'),
      confirm_cert_password: (value, values) =>
        matchesField(value, values.cert_password) ? null : 'Las contraseñas no coinciden',
      file: (value) => (value !== null ? null : 'Debe seleccionar un archivo'),
    },
  });

  const onChangeTax = (value: string) => {
    format(value);
    form.setFieldValue('tax_id', format(value, { dots: false }));
  };

  return (
    <Modal.Root size="xl" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content py={20} px={20}>
        <Modal.Header>
          <Modal.Title fz={20} fw={700}>
            Firma electrónica
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Text>
            Es necesario que cargues tu firma electrónica (certificado digital) para que podamos
            operar a través del Sistema de Impuestos Internos (SII).
          </Text>
          <Text fw={600} my={8}>
            Requisitos
          </Text>
          <ListComponent />
          <form
            onSubmit={form.onSubmit((values, event) => {
              event?.preventDefault();
              event?.stopPropagation();
              const formData = new FormData();
              formData.append('tax_id', values.tax_id);
              formData.append('cert_password', values.cert_password);
              formData.append('file', values.file as File);
              AddCredential({ data: formData });
              form.reset();
              close();
            })}
          >
            <TextInput
              withAsterisk
              label="RUT"
              placeholder="RUT"
              {...form.getInputProps('tax_id')}
              onChange={(event) => onChangeTax(event.target.value)}
            />
            <PasswordInput
              withAsterisk
              label="Contraseña del certificado"
              placeholder="Contraseña del certificado"
              {...form.getInputProps('cert_password')}
            />
            <PasswordInput
              withAsterisk
              label="Confirmar contraseña"
              placeholder="Confirmar contraseña"
              {...form.getInputProps('confirm_cert_password')}
            />
            <Stack align="center" mt={15}>
              <Input
                renderRoot={(props) => (
                  <>
                    <DropzoneComponent
                      form={form}
                      fieldName="file"
                      unique
                      allowExtensions={allowExtensions}
                      {...props}
                    />
                    <Input.Error mt={5}>{form.errors.file}</Input.Error>
                  </>
                )}
              />
            </Stack>
            <Group
              bg={'var(--mantine-primary-color-filled)'}
              c="white"
              h={50}
              px={20}
              mt={15}
              mb={30}
              style={{ borderRadius: 5 }}
              className="light-gray-border"
            >
              <IconInfoCircle />
              <Text fw={500} fz={15} ms={10}>
                Extensiones soportadas: *.pfx
              </Text>
            </Group>
            <Group justify="flex-end">
              <Button w={200} type="submit">
                Subir
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

export default UploadCredentialModal;
