import { useEffect, FormEvent } from 'react';
import { AxiosError } from 'axios';
import { useForm, UseFormReturnType } from '@mantine/form';
import {
  Stack,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Select,
  Text,
  TextInput,
  rem,
  Card,
  Image,
} from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { format } from 'rut.js';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { CommonHash, Commune } from '@types';
import { useConvertReceipt, useDocumentUnauthenticated } from '@api/documents';
import { useCommunesUnauthenticated } from '@api/communes';
import { useAccount } from '@api/accounts';
import Logo from '@assets/whiteLogo.png';

import {
  taxIdValidator,
  companyValidator,
} from '@pages/authenticated/documents/form/formValidators';
import styles from './convertReceipt.module.scss';

function Form() {
  const { uuid } = useParams();
  const {
    data: document,
    isLoading,
    error,
    isError,
  } = useDocumentUnauthenticated(uuid?.split(':')[0]);
  const { data: account } = useAccount(uuid?.split(':')[1]);
  const { mutate: ConvertReceipt, isSuccess } = useConvertReceipt();
  const navigate = useNavigate();

  const onBlurTax = (value: string) => {
    if (value === '') return;
    form.setFieldValue('company.tax_id', format(value, { dots: false }));
  };
  const { data: communes } = useCommunesUnauthenticated();

  const transformedCommunes = communes?.data.map((commune: Commune) => {
    return {
      label: commune.name,
      value: commune.name,
    };
  });
  const form: UseFormReturnType<any> = useForm({
    initialValues: {
      company: {
        tax_id: '',
        legal_name: '',
        activity: '',
        address: '',
        commune: '',
      },
    },
    validateInputOnBlur: true,
    validate: {
      company: {
        tax_id: (value) => taxIdValidator('ElectronicInvoice', value),
        legal_name: (value) => companyValidator('ElectronicInvoice', value),
        activity: (value) => companyValidator('ElectronicInvoice', value),
        address: (value) => companyValidator('ElectronicInvoice', value),
        commune: (value) => companyValidator('ElectronicInvoice', value),
      },
    },
  });

  useEffect(() => {
    if (isSuccess) {
      navigate('/convertir-boleta/exito');
    }
  }, [isSuccess]);

  const contactEmail = document?.data.contact_email?.[0] || '';
  return (
    <div className={styles.container}>
      <Grid px={{ base: '30px', md: '200px' }}>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Stack align="center" justify="center" pt={20} h="100%">
            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Image src={account?.data.logo} h={{ base: 60, md: 160 }} alt="Logo" />
            </Card>
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }} pt={{ base: 10, md: 30 }}>
          <form
            className={styles.formContainer}
            onSubmit={form.onSubmit(
              (values: CommonHash, event: FormEvent<HTMLFormElement> | undefined) => {
                event?.preventDefault();
                event?.stopPropagation();
                if (document) {
                  ConvertReceipt({ uuid: document?.data.uuid, data: values });
                }
              },
              (validationErrors, values, event) => {
                console.log(validationErrors, values, event);
              },
            )}
          >
            <Stack gap={10} pb={30} pos="relative">
              <LoadingOverlay
                visible={isLoading}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
              />
              <Paper shadow="xl" p="lg" className={styles.paperContainer}>
                <Group align="center" justify="center">
                  <Text fw={700} mb={10} fz={20}>
                    Pedido N° {document?.data.references[0]?.original_number}
                  </Text>
                </Group>
                <Text fw={600} mb={10} fz={18}>
                  Receptor
                </Text>
                <Grid>
                  <Grid.Col span={12} pb={0}>
                    <TextInput
                      withAsterisk
                      label="RUT"
                      placeholder="RUT"
                      {...form.getInputProps('company.tax_id')}
                      onBlur={(e) => onBlurTax(e.target.value)}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} pb={0}>
                    <TextInput
                      withAsterisk
                      label="Razón Social"
                      placeholder="Razón Social"
                      maxLength={100}
                      {...form.getInputProps('company.legal_name')}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} pb={0}>
                    <TextInput
                      withAsterisk
                      label="Giro"
                      placeholder="Giro"
                      maxLength={40}
                      {...form.getInputProps('company.activity')}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} pb={0}>
                    <TextInput
                      withAsterisk
                      label="Dirección"
                      placeholder="Dirección"
                      maxLength={70}
                      {...form.getInputProps('company.address')}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} pb={0}>
                    <Select
                      withAsterisk
                      label="Comuna"
                      placeholder="Comuna"
                      data={transformedCommunes}
                      {...form.getInputProps('company.commune')}
                      value={form.values.company.commune}
                      onChange={(value) => form.setFieldValue('company.commune', value)}
                      searchable
                    />
                  </Grid.Col>
                </Grid>
                <Group align="center" justify="center" pt={20}>
                  {!isError && (
                    <Group
                      bg={'var(--mantine-primary-color-filled)'}
                      c="white"
                      h={30}
                      px={15}
                      mt={5}
                      // mb={30}
                      style={{ borderRadius: 5 }}
                      gap={5}
                      className="light-gray-border"
                    >
                      <IconInfoCircle
                        style={{
                          width: rem(20),
                          height: rem(20),
                        }}
                      />
                      <Text fw={500} fz={12} ms={10}>
                        Se le enviará a {contactEmail} la factura
                      </Text>
                    </Group>
                  )}
                  <Button type="submit" w={200} disabled={isError}>
                    Emitir Factura
                  </Button>
                  {isError && error instanceof AxiosError && (
                    <Text c="red" fw={800}>
                      {error?.response?.data.message}
                    </Text>
                  )}
                </Group>
              </Paper>
            </Stack>
          </form>
        </Grid.Col>
      </Grid>
      <Group align="center" justify="center">
        <Link to="https://tufacturador.plaglabs.com" target="_blank">
          <img src={Logo} alt="logo" style={{ width: '200px' }} />
        </Link>
      </Group>
    </div>
  );
}

export default Form;
