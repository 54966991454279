import { Paper, Stack, TextInput, Group, Button, Select } from '@mantine/core';
import { Account } from '@types';
import { useForm, } from '@mantine/form';
import { useUpdateBankingDetailsAccount } from '@api/accounts';
import { emailValidator } from '@pages/authenticated/documents/form/formValidators';
import { validate } from 'rut.js';
import { useEffect, useState } from 'react';
import { formatRut } from '@utils';


const bank_names = [
  'Banco BICE',
  'Banco Consorcio',
  'Banco Corpbanca',
  'BCI',
  'Banco Falabella',
  'Banco Internacional',
  'Banco Paris',
  'Banco Ripley',
  'Banco Santander',
  'Banco Security',
  'Banco de Chile / Edwards-Citi',
  'Banco del Desarrollo',
  'Itaú',
  'Scotiabank',
  'Mercado Pago'
]

const account_types = [
  'Cuenta Corriente',
  'Cuenta Vista',
  'Cuenta de Ahorro',
]


function EditBankDetailsForm({
  account,
  initialDetails,
  onCancel,
}: {
  account?: Account;
  initialDetails?: any;
  onCancel: () => void,
}) {
  const { mutate: UpdateBankingDetailsAccount, isLoading, isSuccess } = useUpdateBankingDetailsAccount();
  const [rut, setRut] = useState('');
  const form = useForm({
    initialValues: {
      company_name: initialDetails?.company_name || account?.legal_name,
      rut: initialDetails?.rut || account?.tax_id,
      bank_name: initialDetails?.bank_name || '',
      account_type: initialDetails?.account_type || '',
      account_number: initialDetails?.account_number || '',
      contact_email: initialDetails?.contact_email || '',
    },
    validate: {
      company_name: (value) => value.length > 0 ? null : 'Este campo es requerido',
      rut: (value) => value != '' && validate(value) ? null : 'Este rut es inválido',
      bank_name: (value) => value != '' ? null : 'Este campo es requerido',
      account_type: (value) => value != '' ? null : 'Este campo es requerido',
      account_number: (value) => value != '' ? null : 'Este campo es requerido',
      contact_email: (value) => {
        if (value.length === 0) return 'Este campo es requerido';
        return emailValidator(value, false);
      },
    },
  });

  useEffect(() => {
    if (isSuccess) {
      onCancel();
    }
  }
    , [isSuccess]);

  function handleRutChange(value: string) {
    setRut(formatRut(value));
    form.setFieldValue('rut', formatRut(value));

  }

  return (
    <Paper shadow="xs" px={15} py={20} withBorder style={{ width: '35vw' }} >
      <Stack>
        <form
          onSubmit={form.onSubmit((values, events) => {
            events?.preventDefault();
            events?.stopPropagation();
            const payload = {
              banking_details: values
            };
            UpdateBankingDetailsAccount({ data: payload });
          },

          )}
        >
          <TextInput
            label="Nombre de la empresa"
            value={form.values.company_name}
            key={form.key('company_name')}
            {...form.getInputProps('company_name')}
          />
          <TextInput
            label="RUT"
            value={rut}
            {...form.getInputProps('rut')}
            onChange={(e) => handleRutChange(e.currentTarget.value)}
          />
          <Select
            label="Banco"
            placeholder='Seleccione un banco'
            data={bank_names}
            searchable
            value={form.values.bank_name}
            key={form.key('bank_name')}
            {...form.getInputProps('bank_name')}
          />
          <Select
            label="Tipo de cuenta"
            placeholder='Seleccione un tipo de cuenta'
            data={account_types}
            searchable
            value={form.values.account_type}
            key={form.key('account_type')}
            {...form.getInputProps('account_type')}
          />
          <TextInput
            label="Número de cuenta"
            value={form.values.account_number}
            key={form.key('account_number')}
            {...form.getInputProps('account_number')}
          />
          <TextInput
            label="Email"
            value={form.values.contact_email}
            key={form.key('contact_email')}
            {...form.getInputProps('contact_email')}
          />
          <Group mt="md" justify="flex-end">
            {initialDetails && (<Button variant="subtle" onClick={onCancel}>Cancelar</Button>)}
            <Button type='submit' loading={isLoading} disabled={isLoading}>Guardar</Button>
          </Group>
        </form>
      </Stack>
    </Paper >
  );
}

export default EditBankDetailsForm;