import { Link } from 'react-router-dom';
import { Modal, Button, Group, Stack, rem, Text } from '@mantine/core';
import { IconFileOff } from '@tabler/icons-react';
interface VoidDocumentProps {
  opened: boolean;
  close: () => void;
  dteType: string;
}

export const NoFoliosModal = ({ opened, close, dteType }: VoidDocumentProps) => {
  return (
    <Modal.Root size="lg" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack justify="center" align="center" px={30} pb={10}>
            <IconFileOff
              style={{ width: rem(150), height: rem(150) }}
              stroke={1.5}
              color="var(--mantine-color-red-8)"
            />
            <Text fz={18}>
              No tienes folios disponible para {dteType}. Puedes solicitar más folios en la sección
              de Timbrar Folios
            </Text>
          </Stack>
          <Group justify="end">
            <Button onClick={close} className="btn-outline-primary">
              Cerrar
            </Button>
            <Button component={Link} to="/folios">
              Ir a Timbrar Folios
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
