import { useRef } from 'react';
import { Group, Text, rem, Stack, CloseButton, Button } from '@mantine/core';
import { IconUpload, IconX, IconPaperclip } from '@tabler/icons-react';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { UseFormReturnType } from '@mantine/form';

interface CompProps {
  unique?: boolean;
  allowExtensions: string[];
  fieldName: string;
  form: UseFormReturnType<any>;
}
export default function DropzoneComponent({ unique, allowExtensions, fieldName, form }: CompProps) {
  const openRef = useRef<() => void>(null);
  const handleFileDelete = (index: number) => {
    if (unique) {
      form.setFieldValue(fieldName, null);
      // setFiles([]);
    } else {
      form.setFieldValue(fieldName, (prevFiles: File[]) => prevFiles.filter((_, i) => i !== index));
    }
  };
  const handleFileAddition = (files: FileWithPath[]) => {
    if (unique) {
      form.setFieldValue(fieldName, files[0]);
      // setFiles(file);
    } else {
      form.setFieldValue(fieldName, (prevFiles: File[]) => prevFiles.concat(...files));
      // setFiles((prevFiles) => [...prevFiles, ...file]);
    }
  };

  const fileDisplay = () => {
    if (Array.isArray(form.values[fieldName])) {
      return form.values[fieldName];
    } else if (unique && form.values[fieldName] !== null) {
      return [form.values[fieldName]];
    } else {
      return [];
    }
  };

  return (
    <Dropzone
      onDrop={(file) => handleFileAddition(file)}
      openRef={openRef}
      onReject={(file) => console.log('rejected files', file)}
      maxSize={5 * 1024 ** 2}
      accept={allowExtensions}
      activateOnClick={false}
    >
      <Group justify="center" gap="sm" mih={220}>
        <Dropzone.Accept>
          <IconUpload
            style={{ width: rem(100), height: rem(100), color: 'var(--mantine-color-blue-6)' }}
            stroke={1.5}
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            style={{ width: rem(100), height: rem(100), color: 'var(--mantine-color-red-6)' }}
            stroke={1.5}
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconPaperclip
            style={{ width: rem(100), height: rem(100), color: 'var(--mantine-color-dimmed)' }}
            stroke={1.5}
          />
        </Dropzone.Idle>
        <Stack gap={0} align="center">
          {fileDisplay().map((file: File, index: number) => (
            <Group key={index} align="center" gap={0}>
              <Text c="dimmed" size="sm">
                {file.name}
              </Text>
              <CloseButton
                size="sm"
                onClick={() => handleFileDelete(index)}
                style={{ pointerEvents: 'all' }}
              />
            </Group>
          ))}
          <Button
            variant="transparent"
            fz={20}
            c="blue"
            fw={700}
            mt={20}
            onClick={() => openRef.current?.()}
            style={{ pointerEvents: 'all' }}
          >
            Seleccionar archivo
          </Button>
          <Text size="sm" c="dimmed" inline>
            o arrasta y suelta los archivos que deseas subir
          </Text>
        </Stack>
      </Group>
    </Dropzone>
  );
}
