import axios from 'axios';
import localforage from 'localforage';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { User, Account } from '@types';
const STORED_SESSION_KEY = 'session';

interface Session {
  user?: User;
  activeAccount?: Account;
  token?: string;
  status: 'loggedIn' | 'loggedOut' | 'loading';
}
interface AuthContext {
  session: Session;
  setSession: (s: Partial<Session> | null) => void;
  isLoggedIn: boolean;
}

const defaultState: Session = {
  status: 'loading',
};

export const AuthContext = createContext<AuthContext>({
  session: defaultState,
  isLoggedIn: false,
  setSession: () => {},
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [session, setSession_] = useState<Session>(defaultState);

  useEffect(() => {
    localforage
      .getItem(STORED_SESSION_KEY)
      .then((stored: unknown) => {
        if (!stored || (stored as Session).status !== 'loggedIn') stored = { status: 'loggedOut' };
        setSession_(stored as Session);
      })
      .catch(() => {
        setSession_({ status: 'loggedOut' });
      });
  }, []);

  useEffect(() => {
    if (session?.token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${session.token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
    localforage.setItem(STORED_SESSION_KEY, session || defaultState);
  }, [session]);

  const contextValue = useMemo(() => {
    const setSession = (newSession: Partial<Session> | null) => {
      if (newSession) {
        setSession_({ ...session, ...newSession });
      } else {
        setSession_(defaultState);
      }
    };

    const isLoggedIn = session.status === 'loggedIn';
    return { session, setSession, isLoggedIn };
  }, [session]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
