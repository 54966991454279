import request from '@utils/axios-utils';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { ApiList, FileImport, CommonHash, ApiObject } from '@types';
import { useAuth } from '@providers/auth';
import Notification from '@components/notification/notification';

const PER_PAGE = 10;
export function useFileImports(page: number) {
  const { session } = useAuth();
  return useQuery<ApiList<FileImport>>(
    ['fileImports', page],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/file_imports?page_size=${PER_PAGE}&page=${page}`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}

export function useAddFileImport() {
  const queryClient = useQueryClient();
  const { session } = useAuth();
  return useMutation(
    ({ data }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/file_imports`,
        method: 'POST',
        data,
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Se ha cargado el archivo.' });
        queryClient.invalidateQueries(['fileImports', 1]);
      },
    },
  );
}

export function useFileImportDocuments(
  uuid: string,
  start_date: string | null,
  end_date: string | null,
  tax_id: string[],
  legal_name: string[],
  document_type: string[],
  status: string[],
  page: number,
  key: string,
  direction: string,
) {
  const { session } = useAuth();
  return useQuery<ApiList<Document>>(
    [
      'file_imports',
      uuid,
      start_date,
      end_date,
      tax_id,
      legal_name,
      document_type,
      status,
      page,
      key,
      direction,
    ],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/file_imports/${uuid}/documents?start_date=${start_date}&end_date=${end_date}&tax_id=${tax_id}&legal_name=${legal_name}&document_type=${document_type}&status=${status}&page=${page}&page_size=${PER_PAGE}&key=${key}&direction=${direction}`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading' && uuid != '',
    },
  );
}

export function useMassiveIssuance() {
  const queryClient = useQueryClient();
  const { session } = useAuth();
  return useMutation(
    ({ uuid }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/file_imports/${uuid}/massive_issuance`,
        method: 'PATCH',
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Se han enviado a emitir los documentos.' });
        queryClient.invalidateQueries(['file_imports']);
      },
    },
  );
}

export function useFileImport(uuid: string | undefined) {
  const { session } = useAuth();
  return useQuery<ApiObject<FileImport>>(
    ['file_imports', uuid],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/file_imports/${uuid}`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading' && uuid !== '' && uuid !== undefined,
      onSuccess: () => {},
    },
  );
}

export function useProccesedFile(uuid: string) {
  const { session } = useAuth();
  return useQuery<string>(
    [uuid],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/file_imports/${uuid}/generate_file`,
        method: 'GET',
      });
      const blob = new Blob([response.data], { type: 'text/plain' });
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', `archivo_procesado.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      return fileURL;
    },
    {
      enabled: false,
    },
  );
}
