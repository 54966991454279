import styles from './convertReceipt.module.scss';
import { Paper, Text, Stack } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';
function SuccessTransformation() {
  return (
    <div className={styles.containerSuccess}>
      <Paper shadow="xl" p="lg" className={styles.successCard}>
        <Stack align="center" justify="center">
          <IconCircleCheck size={50} color="#00a7e1" />
          <Text fw={700} mb={10} fz={20}>
            ¡Transformación exitosa!
          </Text>
        </Stack>
      </Paper>
    </div>
  );
}

export default SuccessTransformation;
