import { Modal, Button, Group, Tooltip, rem, TagsInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useResendPDFMutation } from '@api/documents';
import { CommonHash } from '@types';
import { IconInfoCircle } from '@tabler/icons-react';
interface ResendPDFModalProps {
  opened: boolean;
  close: () => void;
  row: CommonHash;
}

export const ResendPDFModal = ({ opened, close, row }: ResendPDFModalProps) => {
  const { mutate: resendPDF } = useResendPDFMutation();
  const contactEmailTooltip = (
    <Tooltip label="Se puede agregar un máximo de 3 emails. Apretar enter para agregarlo">
      <IconInfoCircle
        style={{ width: rem(16), height: rem(16), color: 'var(--mantine-color-grey-4)' }}
      />
    </Tooltip>
  );

  const emailValidation = (emails: string[]) => {
    if (emails.length === 0) return 'Este campo es requerido';
    if (emails.some((email) => !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email)))
      return 'Email inválido';
    return null;
  };
  const form = useForm({
    initialValues: { contact_email: row.contact_email?.split(';') || [] },
    validateInputOnChange: true,
    validateInputOnBlur: true,
    validate: {
      contact_email: (value) => emailValidation(value),
    },
  });
  return (
    <Modal.Root size="lg" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fz={20} fw={600}>
            Reenviar {row?.dte_type} N° {row?.number}
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={form.onSubmit((values, event) => {
              event?.preventDefault();
              event?.stopPropagation();
              close();
              resendPDF({ uuid: row.uuid as string, contact_email: values.contact_email });
            })}
          >
            <Group mb={30} justify="center">
              <TagsInput
                label="Email de contacto"
                placeholder="Email"
                maxTags={3}
                rightSection={contactEmailTooltip}
                clearable
                {...form.getInputProps('contact_email')}
                splitChars={[',', ' ', '|', ';']}
              />
            </Group>
            <Group justify="end">
              <Button onClick={close}>Cerrar</Button>
              <Button type="submit">Reenviar</Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
