import { Modal, Button, Stack, Input, Text, Group, Loader } from '@mantine/core';

import { Link } from 'react-router-dom';
import { useForm } from '@mantine/form';
import DropzoneComponent from '@components/Dropzone';
import { IconInfoCircle } from '@tabler/icons-react';
import { useAddFileImport } from '@api/fileImports';

interface CompProps {
  opened: boolean;
  close: () => void;
}

interface FormProps {
  file: File | null;
}

function CreateFileImportModal({ opened, close }: CompProps) {
  const { mutate: addFileImport, isLoading } = useAddFileImport();
  const allowExtensions = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const form = useForm<FormProps>({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      file: null,
    },

    validate: {
      file: (value) => (value === undefined ? 'Debes subir al menos un archivo' : null),
    },
  });
  return (
    <Modal.Root size="md" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fz={20} fw={600}>
            Cargar Archivo Masivo
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={form.onSubmit((values, event) => {
              event?.preventDefault();
              event?.stopPropagation();
              const formData = new FormData();
              formData.append(`file`, values.file as File);
              addFileImport({ data: formData });
              close();
              form.reset();
            })}
          >
            <Stack>
              {/* <Text>
                Template para Google docs:
                <Link
                  to="https://docs.google.com/spreadsheets/d/1uFpo4kNueoD2aCYD4T4jCh6dS0w_xq14EJgfA1GXsrg/edit?usp=sharing"
                  target="_blank"
                  color="var(--mantine-primary-color-filled)"
                >
                  Template Google docs
                </Link>
              </Text> */}
              <Text>
                Template para Excel:
                <Link
                  to="https://storage.googleapis.com/documentos-prod/TemplateTuFacturador.xlsx"
                  target="_blank"
                  color="var(--mantine-primary-color-filled)"
                >
                  Template Excel
                </Link>
              </Text>
              <Input
                renderRoot={(props) => (
                  <>
                    <DropzoneComponent
                      unique
                      form={form}
                      fieldName="file"
                      allowExtensions={allowExtensions}
                      {...props}
                    />
                    <Input.Error mt={5}>{form.errors.file}</Input.Error>
                  </>
                )}
              />
              <Group
                bg={'var(--mantine-primary-color-filled)'}
                c="white"
                h={50}
                px={20}
                mt={15}
                style={{ borderRadius: 5 }}
                className="light-gray-border"
              >
                <IconInfoCircle />
                <Text fw={500} fz={15} ms={10}>
                  Extensiones soportadas: *.xlsx
                </Text>
              </Group>
              <Stack align="end">
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? <Loader type="dots" /> : 'Subir'}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

export default CreateFileImportModal;
