import { Navigate } from 'react-router-dom';
import { useAuth } from '@providers/auth';
import { Template } from '@pages/authenticated/Template';

export const ProtectedRoute = () => {
  const { session } = useAuth();

  if (session.status === 'loggedOut') {
    return <Navigate to="/login" />;
  }

  return <Template />;
};
