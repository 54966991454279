import request from '@utils/axios-utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiList, Caf, CommonHash } from '@types';
import Notification from '@components/notification/notification';
import { useAuth } from '@providers/auth';

export function useCafs() {
  const { session } = useAuth();
  return useQuery<ApiList<Caf>>(
    ['cafs'],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/cafs`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}

export function useAddCaf() {
  const queryClient = useQueryClient();
  const { session } = useAuth();
  return useMutation(
    ({ data }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/cafs`,
        method: 'POST',
        data,
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Se han cargado los folios.' });
        queryClient.invalidateQueries('cafs');
      },
    },
  );
}

export function useStampCafs() {
  const queryClient = useQueryClient();
  const { session } = useAuth();
  return useMutation(
    ({ data }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/cafs/stamp`,
        method: 'POST',
        data,
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Se esta procesando el timbraje' });
        queryClient.invalidateQueries('cafs');
      },
    },
  );
}
