import { Menu, Button, rem } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconCirclePlus, IconPlus } from '@tabler/icons-react';
import { receiptType } from './formUtils';
import styles from '@pages/authenticated/documents/Create.module.scss';

function SectionButton({
  form,
  setShowReceiver,
}: {
  form: UseFormReturnType<any>;
  setShowReceiver: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const handleAddReference = () => {
    form.insertListItem('document.references_attributes', {
      original_number: '',
      original_dte_type: '',
      date: '',
      global_index: false,
      code: '',
      description: '',
      _destroy: false,
    });
  };
  const handleAddCharge = () => {
    form.insertListItem('document.charges_attributes', {
      kind: '',
      description: '',
      charge: '',
      net_amount: '',
      exempt_amount: '',
      tax_amount: '',
    });
  };
  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button className={styles.addBtnSection}>
          <IconPlus />
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Agregar sección</Menu.Label>
        {receiptType(form.values.document.type) === true ? (
          <Menu.Item
            leftSection={<IconCirclePlus style={{ width: rem(14), height: rem(14) }} />}
            onClick={() => setShowReceiver(true)}
          >
            Receptor
          </Menu.Item>
        ) : (
          <>
            <Menu.Item
              leftSection={<IconCirclePlus style={{ width: rem(14), height: rem(14) }} />}
              onClick={handleAddReference}
            >
              Referencias
            </Menu.Item>
            <Menu.Item
              leftSection={<IconCirclePlus style={{ width: rem(14), height: rem(14) }} />}
              onClick={() => form.setFieldValue('document.transport_attributes.visible', true)}
            >
              Transporte
            </Menu.Item>
          </>
        )}
        {form.values.document.type === 'ElectronicSettlement' && (
          <Menu.Item
            leftSection={<IconCirclePlus style={{ width: rem(14), height: rem(14) }} />}
            onClick={handleAddCharge}
          >
            Cargos/Comisiones
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}

export default SectionButton;
