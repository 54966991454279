import { Text, Stack } from '@mantine/core';
import { Document } from '@types';
import styles from './ModalPrevisualization.module.scss';

function Totals({ document }: { document?: Document }) {
  const lineTotals = document?.lines.reduce((acc, line) => acc + line.total_amount, 0) || 0;
  const globalDiscountPercentage = document?.global_discounts
    ?.filter((discount) => discount.category === 'discount')
    .reduce((acc, discount) => acc + discount.value, 0);
  const globalDiscountAmount = Math.round((lineTotals * (globalDiscountPercentage || 0)) / 100);
  return (
    <Stack mt={40} gap="0" align="flex-end">
      <div className={styles.totalBox}>
        {globalDiscountAmount !== 0 && <TotalLine label="Descuento" value={globalDiscountAmount} />}
        {document?.net_amount !== 0 && <TotalLine label="Neto" value={document?.net_amount || 0} />}
        {document?.exempt_amount !== 0 && (
          <TotalLine label="Exento" value={document?.exempt_amount || 0} />
        )}
        {document?.tax_amount !== 0 && <TotalLine label="IVA" value={document?.tax_amount || 0} />}
        {document?.charges !== undefined && document?.charges.length > 0 && (
          <>
            <TotalLine label="Neto Comisión" value={document?.charges_net_amount || 0} />
            <TotalLine label="Exento Comisión" value={document?.charges_exempt_amount || 0} />
            <TotalLine label="IVA Comisión" value={document?.charges_tax_amount || 0} />
          </>
        )}
        <TotalLine label="Total" value={document?.total_amount || 0} />
      </div>
    </Stack>
  );
}

export default Totals;

function TotalLine({ label, value }: { label: string; value: number }) {
  return (
    <div className={styles.totalLine}>
      <div className={styles.dFlex}>
        <Text fz={12} className={styles.totalLabel}>
          {label}
        </Text>
      </div>
      <div className={styles.justifyEnd}>
        <Text fz={10} ms={10}>
          ${value.toLocaleString('es-CL')}
        </Text>
      </div>
    </div>
  );
}
