import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import {
  Table,
  ScrollArea,
  Text,
  ActionIcon,
  Menu,
  Skeleton,
  rem,
  Badge,
  Loader,
  Button,
} from '@mantine/core';
import { IconDots, IconEye, IconDownload } from '@tabler/icons-react';
import { FileImport } from '@types';
import ErrorModal from './ErrorModal';
import { useProccesedFile } from '@api/fileImports';
import styles from './FileImport.module.scss';

interface TableProps {
  data: FileImport[];
  isFetching: boolean;
}

interface ItemButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  'data-uuid': string;
}

function ItemButton(props: ItemButtonProps) {
  const { refetch } = useProccesedFile(props['data-uuid']);

  return (
    <Button
      {...props}
      onClick={() => refetch()}
      variant="transparent"
      fw={400}
      leftSection={<IconDownload style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
      className={styles.downloadButton}
      styles={{
        inner: { justifyContent: 'start' },
      }}
    >
      Achivo procesado
    </Button>
  );
}

export default function TableData({ data, isFetching }: TableProps) {
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  let rows;
  if (isFetching || sortedData === undefined) {
    rows = Array.from({ length: 5 }, (_, index) => (
      <Table.Tr key={index}>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
        <Table.Td>
          <Skeleton visible={isFetching} height={rem(30)} />
        </Table.Td>
      </Table.Tr>
    ));
  } else {
    rows = sortedData.map((row: any) => <TableRow row={row} key={row.uuid} />);
  }

  return (
    <ScrollArea>
      <Table horizontalSpacing="md" verticalSpacing="md" miw={700} highlightOnHover withTableBorder>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Nombre</Table.Th>
            <Table.Th>Estado</Table.Th>
            <Table.Th>Fecha de creación</Table.Th>
            <Table.Th>Acciones</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <Table.Tr>
              <Table.Td colSpan={data && data[0] ? Object.keys(data[0])?.length : 0}>
                <Text fw={500} ta="center">
                  No hay archivos
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  );
}

const TableRow = ({ row }: { row: FileImport }): React.ReactElement => {
  const [openedErrorModal, { open: openErrorModal, close: closeErrorModal }] = useDisclosure(false);
  return (
    <>
      <Table.Tr key={row.uuid}>
        <Table.Td>{row.filename}</Table.Td>
        <Table.Td>
          <BadgeStatus status={row.status} />
        </Table.Td>
        <Table.Td>{row.created_at}</Table.Td>
        <Table.Td>
          <Menu
            transitionProps={{ transition: 'pop' }}
            withArrow
            position="bottom-end"
            withinPortal
          >
            <Menu.Target>
              <ActionIcon variant="subtle" color="gray">
                <IconDots style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <DropdownItems row={row} openErrorModal={openErrorModal} />
              {/* <Menu.Item
                  leftSection={
                    <IconFileX style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                  }
                  color="red"
                >
                  Eliminar
                </Menu.Item> */}
            </Menu.Dropdown>
          </Menu>
          <ErrorModal opened={openedErrorModal} close={closeErrorModal} errors={row.error_logs} />
        </Table.Td>
      </Table.Tr>
    </>
  );
};

function DropdownItems({ row, openErrorModal }: { row: FileImport; openErrorModal: () => void }) {
  if (row.status === 'with_errors') {
    return (
      <>
        <Menu.Item
          leftSection={<IconEye style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
          onClick={openErrorModal}
        >
          Ver Errores
        </Menu.Item>
        <Menu.Item
          leftSection={<IconDownload style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
          component={Link}
          to={String(row.loaded_file)}
          target="_blank"
        >
          Archivo subido
        </Menu.Item>
      </>
    );
  } else if (row.status === 'processing') {
    return (
      <>
        <Menu.Item leftSection={<Loader size="xs" type="dots" />}>Generando documentos</Menu.Item>
        <Menu.Item
          leftSection={<IconDownload style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
          component={Link}
          to={String(row.loaded_file)}
          target="_blank"
        >
          Archivo subido
        </Menu.Item>
      </>
    );
  } else {
    return (
      <>
        <Menu.Item
          leftSection={<IconEye style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
          component={Link}
          to={String(row.uuid)}
        >
          Ver Documentos
        </Menu.Item>
        <Menu.Item
          leftSection={<IconDownload style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
          component={Link}
          to={String(row.loaded_file)}
          target="_blank"
        >
          Archivo subido
        </Menu.Item>
        {row.status === 'emitted' && (
          <Menu.Item
            leftSection={<IconDownload style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            component={ItemButton}
            data-uuid={row.uuid}
          />
        )}
      </>
    );
  }
}

function BadgeStatus({ status }: { status: string }) {
  switch (status) {
    case 'pending':
      return <Badge color="yellow">Pendiente</Badge>;
    case 'processing':
      return <Badge color="blue">Procesando</Badge>;
    case 'with_errors':
      return <Badge color="red">Con Errores</Badge>;
    case 'processed':
      return <Badge color="green">Completado</Badge>;
    case 'emitting':
      return <Badge color="blue">Emitiendose</Badge>;
    case 'emitted':
      return <Badge color="green">Emitido</Badge>;
    default:
      return <Badge color="gray">Pendiente</Badge>;
  }
}
