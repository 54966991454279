import { Modal, Button, Stack, Text, Group, rem, Textarea } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useState } from 'react';
import { useAnswerDocument } from '@api/documents';

interface CompProps {
  opened: boolean;
  close: () => void;
  acceptDocument: boolean;
  uuid: string;
}

function AnswerDocumentModal({ opened, close, acceptDocument, uuid }: CompProps) {
  const message = acceptDocument ? 'aceptar' : 'rechazar';
  const button_class = acceptDocument ? 'accept' : 'delete';
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState(false);
  const { mutateAsync: apiFunction } = useAnswerDocument();
  const [loading, setLoading] = useState(false);

  const handleAction = async () => {
    setCommentError(false);
    if (acceptDocument == false && (!comment || comment.length < 5 || comment.length > 200)) {
      setCommentError(true);
      return;
    }

    setLoading(true);
    await apiFunction({
      data: { id: uuid, answer: acceptDocument ? 'accepted' : 'rejected', comment: comment },
    });
    setLoading(false);

    close();
    setComment('');
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  return (
    <Modal.Root size="sm" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack justify="center" align="center">
            <IconAlertTriangle
              style={{ width: rem(100), height: rem(100), color: 'var(--mantine-color-red-8)' }}
            />
            <Text fz={25} fw={600}>
              ¿Estás seguro?
            </Text>
            <Text fz={18}>Esta acción no se puede deshacer</Text>
            {!acceptDocument && (
              <Textarea
                size="md"
                label="Comentario rechazo"
                placeholder="Escribe aquí..."
                value={comment}
                onChange={handleCommentChange}
                autosize
                minRows={2}
                maxRows={4}
                error={commentError ? 'Debe tener un largo entre 5 y 200 caracteres' : ''}
                w="80%"
                mb="15px"
                mt="5px"
              />
            )}
            <Group>
              <Button className={`${button_class}-button`} onClick={handleAction} loading={loading}>
                Si, {message}
              </Button>
              {!loading && (
                <Button className={`${button_class}-outline-button`} onClick={() => close()}>
                  Cancelar
                </Button>
              )}
            </Group>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

export default AnswerDocumentModal;
