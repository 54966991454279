import {
  TextInput,
  Paper,
  Text,
  Grid,
  Button,
  Stack,
  Group,
  Select,
  NumberInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import styles from '@pages/authenticated/documents/Create.module.scss';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { PACKAGEOPTIONS } from '@constants';

function Packages({
  form,
  packagesAmount,
  setPackagesAmount,
}: {
  form: UseFormReturnType<any>;
  packagesAmount: number;
  setPackagesAmount: React.Dispatch<any>;
}) {
  const packages = Array.from({ length: packagesAmount }, (_, index) => {
    if (!form.values.document.transport_attributes.packages_attributes[index]?._destroy)
      return <PackageLine key={index} index={index} form={form} />;
  });

  const handleAddPackage = () => {
    form.insertListItem('document.transport_attributes.packages_attributes', {
      id: '',
      code: '',
      quantity: '',
      brand: '',
      container_id: '',
      seal: '',
      issuer_seal: '',
      _destroy: false,
    });
    setPackagesAmount((prevValue: number) => prevValue + 1);
  };

  const handleRemovePackage = () => {
    if (form.values.document.transport_attributes.packages_attributes[packagesAmount - 1].id) {
      form.setFieldValue(
        `document.transport_attributes.packages_attributes.${packagesAmount - 1}._destroy`,
        true,
      );
    } else {
      form.removeListItem('document.transport_attributes.packages_attributes', packagesAmount - 1);
    }
    setPackagesAmount((prevValue: number) => prevValue - 1);
  };

  return (
    <Paper shadow="xl" p="lg">
      <Text fw={700} mb={10} fz={20}>
        Bultos
      </Text>
      <Stack>{packages}</Stack>
      <Group justify="center" mt={10}>
        <Button className={styles.addBtn} onClick={handleAddPackage}>
          <IconPlus />
        </Button>
        {canDeleteLine(packagesAmount) && (
          <Button className={styles.addBtn} onClick={handleRemovePackage} color="red">
            <IconMinus />
          </Button>
        )}
      </Group>
    </Paper>
  );
}

function PackageLine({ index, form }: { index: number; form: UseFormReturnType<any> }) {
  return (
    <Grid>
      <Grid.Col span={{ base: 3 }}>
        <Select
          withAsterisk
          searchable
          label="Tipo de Bulto"
          placeholder="Tipo de Bulto"
          data={PACKAGEOPTIONS}
          {...form.getInputProps(`document.transport_attributes.packages_attributes.${index}.code`)}
          value={String(form.values.document.transport_attributes.packages_attributes[index].code)}
          onChange={(value) =>
            form.setFieldValue(
              `document.transport_attributes.packages_attributes.${index}.code`,
              value,
            )
          }
        />
      </Grid.Col>
      <Grid.Col span={{ base: 1 }}>
        <NumberInput
          label="Cantidad"
          placeholder="Cantidad"
          allowDecimal={false}
          {...form.getInputProps(
            `document.transport_attributes.packages_attributes.${index}.quantity`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 2 }}>
        <TextInput
          label="Marcas"
          placeholder="Marcas"
          {...form.getInputProps(
            `document.transport_attributes.packages_attributes.${index}.brand`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 2 }}>
        <TextInput
          label="Id. Container"
          placeholder="Id. Container"
          {...form.getInputProps(
            `document.transport_attributes.packages_attributes.${index}.container_id`,
          )}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 2 }}>
        <TextInput
          label="Sello"
          placeholder="Sello"
          {...form.getInputProps(`document.transport_attributes.packages_attributes.${index}.seal`)}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 2 }}>
        <TextInput
          label="Emisor sello"
          placeholder="Emisor sello"
          {...form.getInputProps(
            `document.transport_attributes.packages_attributes.${index}.issuer_seal`,
          )}
        />
      </Grid.Col>
    </Grid>
  );
}

export default Packages;

function canDeleteLine(packagesAmount: number) {
  if (packagesAmount < 1) return false;
  return true;
}
