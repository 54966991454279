export const PAYMENTTYPES = [
  { value: 'full', label: 'Contado' },
  { value: 'credit', label: 'Crédito' },
  { value: 'no_cost', label: 'Sin costo' },
];
export const KINDOFSALE = [
  { value: 'activity', label: 'Ventas del giro' },
  { value: 'fixed_asset', label: 'Venta activo fijo' },
  { value: 'real_state', label: 'Venta bien raíz' },
];

export const DOCUMENTTYPEOPTIONS = [
  { value: 'ElectronicInvoice', label: 'Factura Electrónica', number: 33, disabled: false },
  {
    value: 'ElectronicExemptInvoice',
    label: 'Factura Exenta Electrónica',
    number: 34,
    disabled: false,
  },
  { value: 'ElectronicReceipt', label: 'Boleta Electrónica', number: 39, disabled: false },
  {
    value: 'ElectronicExemptReceipt',
    label: 'Boleta Exenta Electrónica',
    number: 41,
    disabled: false,
  },
  {
    value: 'ElectronicCreditNote',
    label: 'Nota de Crédito Electrónica',
    number: 61,
    disabled: false,
  },
  {
    value: 'ElectronicDebitNote',
    label: 'Nota de Débito Electrónica',
    number: 56,
    disabled: false,
  },
  {
    value: 'ElectronicPurchaseInvoice',
    label: 'Factura de Compra Electrónica',
    number: 46,
    disabled: false,
  },
  {
    value: 'ElectronicDispatchGuide',
    label: 'Guía de Despacho Electrónica',
    number: 52,
    disabled: false,
  },
  {
    value: 'ElectronicSettlement',
    label: 'Liquidación Factura Electrónica',
    number: 43,
    disabled: false,
  },
  {
    value: 'ElectronicExportInvoice',
    label: 'Factura de Exportación Electrónica',
    number: 110,
    disabled: false,
  },
  {
    value: 'ElectronicExportCreditNote',
    label: 'Nota de Crédito de Exportación Electrónica',
    number: 112,
    disabled: false,
  },
  {
    value: 'ElectronicExportDebitNote',
    label: 'Nota de Débito de Exportación Electrónica',
    number: 111,
    disabled: false,
  },
];

export const DOCUMENTTYPEOPTIONSONFILTER = [
  { value: 'Documents::ElectronicInvoice', label: 'Factura electrónica' },
  { value: 'Documents::ElectronicExemptInvoice', label: 'Factura exenta electrónica' },
  { value: 'Documents::ElectronicReceipt', label: 'Boleta electrónica' },
  { value: 'Documents::ElectronicExemptReceipt', label: 'Boleta exenta electrónica' },
  { value: 'Documents::ElectronicCreditNote', label: 'Nota de crédito electrónica' },
  { value: 'Documents::ElectronicDebitNote', label: 'Nota de débito electrónica' },
  { value: 'Documents::ElectronicPurchaseInvoice', label: 'Factura de compra electrónica' },
  { value: 'Documents::ElectronicDispatchGuide', label: 'Guia de despacho electrónica' },
  { value: 'Documents::ElectronicSettlement', label: 'Liquidación-Factura electrónica' },
  { value: 'Documents::ElectronicExportInvoice', label: 'Factura de exportación electrónica' },
  {
    value: 'Documents::ElectronicExportCreditNote',
    label: 'Nota de crédito de exportación electrónica',
  },
  {
    value: 'Documents::ElectronicExportDebitNote',
    label: 'Nota de débito de exportación electrónica',
  },
];

export const RECEIPTSERVICEINDICATOROPTIONS = [
  { value: 'periodic_service', label: 'Boleta de servicios periódicos' },
  { value: 'periodic_domiciliary_service', label: 'Boleta de servicios periódicos domiciliarios' },
  { value: 'sale_and_service', label: 'Boleta de ventas y servicios' },
  { value: 'show_ticket', label: 'Boleta de espectaculo emitida por cuenta de terceros' },
];

export const GENERALSERVICEINDICATOROPTIONS = [
  { value: '1', label: 'Factura de servicios periódicos domiciliarios' },
  { value: '2', label: 'Factura de otros servicios periódicos' },
  { value: '3', label: 'Factura de Servicios' },
];

export const EXPORTATIONSERVICEINDICATOROPTIONS = [
  { value: '3', label: 'Factura de Servicios' },
  { value: '4', label: 'Servicios de Hotelería' },
  { value: '5', label: 'Servicio de Transporte Terrestre Internacional' },
  { value: '6', label: 'Servicios prestados y utilizados totalmente en el extranjero' },
];
export const EXPORTATIONPAYMENTOPTIONS = [
  { value: '01', label: 'Cobranza hasta 1 año' },
  { value: '02', label: 'Cobranza más de 1 año' },
  { value: '11', label: 'Acreditivo hasta 1 año' },
  { value: '12', label: 'Crédito de bancos y Org. financieros más de 1 año' },
  { value: '21', label: 'Sin pago' },
  { value: '32', label: 'Pago anticipado a la fecha de embarque' },
];

export const EXPORTATIONSALESMODEOPTIONS = [
  { value: '1', label: 'A FIRME' },
  { value: '2', label: 'BAJO CONDICION' },
  { value: '3', label: 'EN CONSIGNACION LIBRE' },
  { value: '4', label: 'EN CONSIGNACION CON UN MINIMO A FIRME' },
  { value: '9', label: 'SIN PAGO' },
];

export const EXPORTATIONSALESCLAUSEOPTIONS = [
  { value: '1', label: 'COSTOS, SEGURO Y FLETE' },
  { value: '2', label: 'COSTOS Y FLETE' },
  { value: '3', label: 'EN FÁBRICA' },
  { value: '4', label: 'FRANCO AL COSTADO DEL BUQUE' },
  { value: '5', label: 'FRANCO A BORDO' },
  { value: '6', label: 'SIN CLÁUSULA DE COMPRAVENTA' },
  { value: '7', label: 'FRANCO TRANSPORTISTA' },
  { value: '9', label: 'ENTREGADAS DERECHOS PAGADOS' },
];

export const CHARGEKINDOPTIONS = [
  { value: 'C', label: 'Comisión' },
  { value: 'O', label: 'Otros Cargos' },
];
export const TRANSPORTROUTES = [
  { value: '1', label: 'MARÍTIMA, FLUVIAL Y LACUSTRE' },
  { value: '4', label: 'AÉREO' },
  { value: '5', label: 'POSTAL' },
  { value: '6', label: 'FERROVIARIO' },
  { value: '7', label: 'CARRETERO/TERRESTRE' },
  { value: '8', label: 'OLEODUCTOS, GASODUCTOS' },
  { value: '9', label: 'TENDIDO ELÉCTRICO (Aéreo, Subterráneo)' },
  { value: '10', label: 'OTRA' },
  { value: '11', label: 'COURIER/AEREO' },
];

export const CURRENCYOPTIONS = [
  'BOLIVAR',
  'BOLIVIANO',
  'CHELIN',
  'CORONA DIN',
  'CORONA NOR',
  'CORONA SC',
  'CRUZEIRO REAL',
  'DIRHAM',
  'DOLAR AUST',
  'DOLAR CAN',
  'DOLAR HK',
  'DOLAR NZ',
  'DOLAR SIN',
  'DOLAR TAI',
  'DOLAR USA',
  'DRACMA',
  'ESCUDO',
  'EURO',
  'FLORIN',
  'FRANCO BEL',
  'FRANCO FR',
  'FRANCO SZ',
  'GUARANI',
  'LIBRA EST',
  'LIRA',
  'MARCO AL',
  'MARCO FIN',
  'NUEVO SOL',
  'OTRAS MONEDAS',
  'PESETA',
  'PESO',
  'PESO CL',
  'PESO COL',
  'PESO MEX',
  'PESO URUG',
  'RAND',
  'RENMINBI',
  'RUPIA',
  'SUCRE',
  'YEN',
];

export const MEASUREMENTUNITOPTIONS = [
  { value: '1', label: 'TONELADA METRICA BRUTA' },
  { value: '2', label: 'QUINTAL METRICO BRUTO' },
  { value: '3', label: '1000 KILOWATT HORA' },
  { value: '4', label: 'TONELADA METRICA NETA' },
  { value: '5', label: 'KILATE' },
  { value: '6', label: 'KILOGRAMO NETO' },
  { value: '7', label: 'GRAMO' },
  { value: '8', label: 'HECTOLITRO' },
  { value: '9', label: 'LITRO' },
  { value: '10', label: 'UNIDAD' },
  { value: '11', label: 'DOCENA' },
  { value: '12', label: 'CENTENA' },
  { value: '13', label: '1000 UNIDADES' },
  { value: '14', label: 'METRO LINEAL' },
  { value: '15', label: 'METRO CUADRADO' },
  { value: '16', label: 'METRO CÚBICO' },
  { value: '17', label: 'PAR' },
  { value: '18', label: 'KILOS NETOS DE FINOS CONTENIDOS' },
  { value: '19', label: 'CARTON (10 CAJETILLAS DE 20 CIGARRILLOS)' },
  { value: '20', label: 'KILOWATTS-HORA' },
  { value: '21', label: 'KILOGRAMO LEGAL' },
  { value: '22', label: 'GALON (3,785 LTS)' },
  { value: '23', label: 'BAR' },
  { value: '24', label: 'METROS CUADRADOS DE 1 MM DE ESPESOR' },
  { value: '25', label: 'LIBRA' },
  { value: '26', label: 'GRUESA' },
  { value: '27', label: 'RESMA' },
  { value: '28', label: 'YARDA' },
  { value: '29', label: 'PIE LINEAL' },
  { value: '30', label: 'PIE CUBICO' },
  { value: '31', label: 'PULGADA LINEAL' },
  { value: '32', label: 'QUINTAL METRICO NETO' },
  { value: '33', label: 'SET' },
  { value: '34', label: 'PIE CUADRADO' },
  { value: '35', label: 'BRAZA' },
  { value: '36', label: 'KILOGRAMO BRUTO' },
  { value: '37', label: 'JUEGO O MAZO' },
];

export const PACKAGEOPTIONS = [
  { value: '1', label: 'GRANEL SÓLIDO, PARTICULAS FINAS (POLVO)' },
  { value: '2', label: 'GRANEL SÓLIDO, PARTICULAS GRANULARES (GRANOS)' },
  { value: '3', label: 'GRANEL SÓLIDO, PARTICULAS GRANDES (NÓDULOS)' },
  { value: '4', label: 'GRANEL LÍQUIDO' },
  { value: '5', label: 'GRANEL GASEOSO' },
  { value: '10', label: 'PIEZAS' },
  { value: '11', label: 'TUBOS' },
  { value: '12', label: 'CILINDRO' },
  { value: '13', label: 'ROLLOS' },
  { value: '16', label: 'BARRAS' },
  { value: '17', label: 'LINGOTES' },
  { value: '18', label: 'TRONCOS' },
  { value: '19', label: 'BLOQUE' },
  { value: '20', label: 'ROLLIZO' },
  { value: '21', label: 'CAJON' },
  { value: '22', label: 'CAJAS DE CARTÓN' },
  { value: '23', label: 'FARDO' },
  { value: '24', label: 'BAÚL' },
  { value: '25', label: 'COFRE' },
  { value: '26', label: 'ARMAZÓN' },
  { value: '27', label: 'BANDEJA' },
  { value: '28', label: 'CAJAS DE MADERA' },
  { value: '29', label: 'CAJAS DE LATA' },
  { value: '31', label: 'BOTELLA DE GAS' },
  { value: '32', label: 'BOTELLA' },
  { value: '33', label: 'JAULAS' },
  { value: '34', label: 'BIDON' },
  { value: '35', label: 'JABAS' },
  { value: '36', label: 'CESTAS' },
  { value: '37', label: 'BARRILETE' },
  { value: '38', label: 'TONEL' },
  { value: '39', label: 'PIPAS' },
  { value: '40', label: 'CAJAS NO ESPECIFICADAS' },
  { value: '41', label: 'JARRO' },
  { value: '42', label: 'FRASCO' },
  { value: '43', label: 'DAMAJUANA' },
  { value: '44', label: 'BARRIL' },
  { value: '45', label: 'TAMBOR' },
  { value: '46', label: 'CUÑETES' },
  { value: '47', label: 'TARROS' },
  { value: '51', label: 'CUBO' },
  { value: '61', label: 'PAQUETE' },
  { value: '62', label: 'SACOS' },
  { value: '63', label: 'MALETA' },
  { value: '64', label: 'BOLSA' },
  { value: '65', label: 'BALA' },
  { value: '66', label: 'RED' },
  { value: '67', label: 'SOBRES' },
  { value: '73', label: 'CONTENEDOR DE 20 PIES DRY' },
  { value: '74', label: 'CONTENEDOR DE 40 PIES DRY' },
  { value: '75', label: 'CONTENEDOR REFRIGERADO 20 PIES' },
  { value: '76', label: 'CONTENEDOR REFRIGERADO 40 PIES' },
  { value: '77', label: 'ESTANQUE (no utilizar para contenedor Tank)' },
  { value: '78', label: 'CONTENEDOR NO ESPECIFICADO (Open Top, Tank, Flat Rack, otros)' },
  { value: '80', label: 'PALLETS' },
  { value: '81', label: 'TABLERO' },
  { value: '82', label: 'LAMINAS' },
  { value: '83', label: 'CARRETE' },
  { value: '85', label: 'AUTOMOTOR' },
  { value: '86', label: 'ATAUD' },
  { value: '88', label: 'MAQUINARIA' },
  { value: '89', label: 'PLANCHAS' },
  { value: '90', label: 'ATADOS' },
  { value: '91', label: 'BOBINA' },
  { value: '93', label: 'OTROS BULTOS NO ESPECIFICADOS' },
  { value: '98', label: 'NO EXISTE BULTO' },
  { value: '99', label: 'SIN EMBALAR' },
];

export const REFERENCEDOCUMENTSOPTIONS = [
  { value: '30', label: '30 - Factura' },
  { value: '32', label: '32 - Factura exenta' },
  { value: '33', label: '33 - Factura electrónica' },
  { value: '34', label: '34 - Factura exenta electrónica' },
  { value: '35', label: '35 - Boleta' },
  { value: '38', label: '38 - Boleta exenta' },
  { value: '39', label: '39 - Boleta electrónica' },
  { value: '40', label: '40 - Liquidación factura' },
  { value: '41', label: '41 - Boleta exenta electrónica' },
  { value: '43', label: '43 - Liquidación Factura electrónica' },
  { value: '45', label: '45 - Factura de compra' },
  { value: '46', label: '46 - Factura de compra electrónica' },
  { value: '48', label: '48 - Comprobantes pago electrónico' },
  { value: '50', label: '50 - Guía de despacho' },
  { value: '52', label: '52 - Guia despacho electrónica' },
  { value: '55', label: '55 - Nota de débito' },
  { value: '56', label: '56 - Nota de débito electrónica' },
  { value: '60', label: '60 - Nota de crédito' },
  { value: '61', label: '61 - Nota de crédito electrónica' },
  { value: '99', label: '99 - Anticipo' },
  { value: '103', label: '103 - Liquidación' },
  { value: '110', label: '110 - Factura de exportación electrónica' },
  { value: '111', label: '111 - Nota de débito de exportación electrónica' },
  { value: '112', label: '112 - Nota de crédito exportación electrónica' },
  { value: '801', label: '801 - Orden de compra' },
  { value: '802', label: '802 - Nota de pedido' },
  { value: '803', label: '803 - Contrato' },
  { value: '804', label: '804 - Resolución' },
  { value: '805', label: '805 - Proceso ChileCompra' },
  { value: '806', label: '806 - Ficha ChileCompra' },
  { value: '807', label: '807 - DUS' },
  { value: '808', label: '808 - B/L (Conocimiento de embarque)' },
  { value: '809', label: '809 - AWB (Air Waybill)a' },
  { value: '810', label: '810 - MIC/DTA' },
  { value: '811', label: '811 - Carta de Porte' },
  { value: '812', label: '812 -  Resolución del SNA donde califica Servicios de Exportación' },
  { value: '813', label: '813 - Pasaporte' },
  { value: '814', label: '814 - Certificado de Depósito Bolsa Prod. Chile.' },
  { value: '815', label: '815 -  Vale de Prenda Bolsa Prod. Chile' },
  {
    value: '820',
    label: '820 - Código de Inscripción en el Registro de Acuerdos con Plazo de Pago Excepcional',
  },
  {
    value: '1000',
    label: '1000 - HES',
  },
];

export const SETTLEMENTLINEOPTIONS = [
  { value: '30', label: '30 - Factura' },
  { value: '32', label: '32 - Factura exenta' },
  { value: '33', label: '33 - Factura electrónica' },
  { value: '34', label: '34 - Factura exenta electrónica' },
  { value: '35', label: '35 - Boleta' },
  { value: '38', label: '38 - Boleta Exenta' },
  { value: '39', label: '39 - Boleta electrónica' },
  { value: '41', label: '41 - Boleta exenta electrónica' },
  { value: '43', label: '43 - Liquidación electrónica' },
  { value: '45', label: '45 - Factura de compra' },
  { value: '46', label: '46 - Factura de compra electrónica' },
  { value: '48', label: '48 - Comprobantes pago electrónico' },
  { value: '50', label: '50 - Guía de despacho' },
  { value: '52', label: '52 - Guia despacho electrónica' },
  { value: '55', label: '55 - Nota de débito' },
  { value: '56', label: '56 - Nota de débito electrónica' },
  { value: '60', label: '60 - Nota de crédito' },
  { value: '61', label: '61 - Nota de crédito electrónica' },
  { value: '99', label: '99 - Anticipo' },
];

export const NOTEREASONS = [
  { value: '1', label: 'Anula Documento de Referencia' },
  { value: '2', label: 'Corrige Texto Documento de Referencia' },
  { value: '3', label: 'Corrige montos' },
];

export const DISPATCHTYPEPEOPTIONS = [
  { value: '1', label: 'Despacho por cuenta del receptor del documento' },
  { value: '2', label: 'Despacho por cuenta del emisor a instalaciones del cliente' },
  { value: '3', label: 'Despacho por cuenta del emisor a otras instalaciones' },
];

export const TRANSFERINDEXOPTIONS = [
  { value: '1', label: 'Operación constituye venta' },
  { value: '2', label: 'Ventas por efectuar' },
  { value: '3', label: 'Consignaciones' },
  { value: '4', label: 'Entrega gratuita' },
  { value: '5', label: 'Traslados internos' },
  { value: '6', label: 'Otros traslados no venta' },
  { value: '7', label: 'Guía de devolución' },
  { value: '8', label: 'Traslado para exportación' },
  { value: '9', label: 'Ventas para exportación' },
];

export const TRANSFERCODEOPTIONS = [
  { value: '1', label: 'Exportador' },
  { value: '2', label: 'Agente de Aduana' },
  { value: '3', label: 'Vendedor' },
  { value: '4', label: 'Contribuyente autorizado expresamente por el SII' },
];

export const BILLINGINDICATOROPTIONS = [
  { value: '1', label: 'Exento o no afecto de IVA' },
  { value: '2', label: 'No facturable' },
  { value: '3', label: 'Garantía de depósito por envases' },
  { value: '4', label: 'Item No Venta' },
  { value: '5', label: 'Item a rebajar' },
  { value: '6', label: 'Producto o servicio no facturable negativo' },
];
