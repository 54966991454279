import { Paper, Stack, Button, Divider, Group } from '@mantine/core';
import { BankingDetails } from '@types';
import Line from '@components/ConfigurationTable/Line';
import { useDeleteBankingDetailsAccount } from '@api/accounts';
import { formatRut } from '@utils/index';


function ShowBankingDetails({
  banking_details,
  isEditing,
  setIsEditing
}: {
  banking_details: BankingDetails;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;

}) {
  const { mutate: DeleteBankingDetailsAccount } = useDeleteBankingDetailsAccount();
  return (
    <Paper shadow="xs" px={15} py={20} withBorder style={{ width: '35vw' }}>
      <Stack px={30} gap={0}>
        <Line name="Nombre" value={banking_details?.company_name} />
        <Divider my={10} />
        <Line name="RUT" value={formatRut(banking_details?.rut)} />
        <Divider my={10} />
        <Line name="Banco" value={banking_details?.bank_name} />
        <Divider my={10} />
        <Line name="Tipo de cuenta" value={banking_details?.account_type} />
        <Divider my={10} />
        <Line name="Número de cuenta" value={banking_details?.account_number} />
        <Divider my={10} />
        <Line name="Email" value={banking_details?.contact_email} />
        <Divider my={10} />
        <Group justify="center" grow >
          <Button variant="subtle" onClick={() => DeleteBankingDetailsAccount()}>
            {'Eliminar'}
          </Button>
          <Button onClick={() => setIsEditing(!isEditing)}>
            {'Editar'}
          </Button>
        </Group>
      </Stack>
    </Paper>
  );
}

export default ShowBankingDetails;