import { ReactNode, createContext, useContext } from 'react';
import { useDisclosure } from '@mantine/hooks';

interface NavbarContext {
  opened: boolean;
  toggleNavbar: () => void;
}

export const NavbarContext = createContext<NavbarContext>({
  opened: true,
  toggleNavbar: () => {},
});

export const useNavbar = () => useContext(NavbarContext);

const NavbarProvider = ({ children }: { children: ReactNode }) => {
  const [opened, { toggle: toggleNavbar }] = useDisclosure();

  return (
    <NavbarContext.Provider value={{ opened, toggleNavbar }}>{children}</NavbarContext.Provider>
  );
};

export default NavbarProvider;
