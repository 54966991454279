import { notifications } from '@mantine/notifications';

interface funcProps {
  type: string;
  message: string;
}

export default function Notification({ type, message }: funcProps) {
  if (type === 'success') {
    notifications.show({
      message,
      color: 'green',
      style: { height: '60px' },
    });
  } else if (type === 'error') {
    notifications.show({
      message,
      color: 'red',
      style: { height: '60px' },
    });
  } else if (type === 'information') {
    notifications.show({
      message,
      style: { height: '60px' },
    });
  }
  return;
}
