import { Grid, Text, Paper, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { format } from 'rut.js';

function Transport({ form }: { form: UseFormReturnType<any> }) {
  const onBlurTax = (value: string) => {
    if (value === '') return;
    form.setFieldValue(
      'document.transport_attributes.driver_company_tax_id',
      format(value, { dots: false }),
    );
  };
  const onBlurDriver = (value: string) => {
    if (value === '') return;
    form.setFieldValue(
      'document.transport_attributes.driver_tax_id',
      format(value, { dots: false }),
    );
  };
  return (
    <Paper shadow="xl" p="lg">
      <Text fw={700} mb={10} fz={20}>
        Transporte
      </Text>
      <Grid>
        <Grid.Col span={{ base: 12, md: 3 }}>
          <TextInput
            label="Patente"
            placeholder="Patente"
            maxLength={8}
            {...form.getInputProps('document.transport_attributes.license_plate')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3 }}>
          <TextInput
            label="RUT Transportista"
            placeholder="RUT Transportista"
            {...form.getInputProps('document.transport_attributes.driver_company_tax_id')}
            onBlur={(e) => onBlurTax(e.target.value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3 }}>
          <TextInput
            label="RUT Chofer"
            placeholder="RUT Chofer"
            {...form.getInputProps('document.transport_attributes.driver_tax_id')}
            onBlur={(e) => onBlurDriver(e.target.value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3 }}>
          <TextInput
            label="Nombre Chofer"
            placeholder="Nombre Chofer"
            {...form.getInputProps('document.transport_attributes.driver_name')}
          />
        </Grid.Col>
        {/* <Grid.Col span={{ base: 12, md: 4 }}>
          <TextInput
            withAsterisk={form.values.document.type === 'ElectronicDispatchGuide'}
            label="Dirección Destino"
            placeholder="Dirección Destino"
            {...form.getInputProps('document.transport_attributes.address')}
          />
        </Grid.Col> */}
        {/* {!exportType(form.values.document.type) && (
          <Grid.Col span={{ base: 12, md: 4 }}>
            <TextInput
              withAsterisk={form.values.document.type === 'ElectronicDispatchGuide'}
              label="Comuna Destino"
              placeholder="Comuna Destino"
              {...form.getInputProps('document.transport_attributes.commune')}
            />
          </Grid.Col>
        )} */}
        {/* <Grid.Col span={{ base: 12, md: 4 }}>
          <TextInput
            withAsterisk={form.values.document.type === 'ElectronicDispatchGuide'}
            label="Ciudad Destino"
            placeholder="Ciudad Destino"
            {...form.getInputProps('document.transport_attributes.city')}
          />
        </Grid.Col> */}
      </Grid>
    </Paper>
  );
}

export default Transport;
