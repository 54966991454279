import { useState, useEffect } from 'react';
import { Grid, Text, Paper, Select, Skeleton, NumberInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DateInput } from '@mantine/dates';
import {
  PAYMENTTYPES,
  KINDOFSALE,
  DOCUMENTTYPEOPTIONS,
  RECEIPTSERVICEINDICATOROPTIONS,
  EXPORTATIONPAYMENTOPTIONS,
  CURRENCYOPTIONS,
  GENERALSERVICEINDICATOROPTIONS,
  EXPORTATIONSERVICEINDICATOROPTIONS,
  DISPATCHTYPEPEOPTIONS,
  TRANSFERINDEXOPTIONS,
  TRANSFERCODEOPTIONS,
} from '@constants';
import { receiptType, exportType, regularNoteType } from './formUtils';
import { useAuthorizedDocuments } from '@api/authorized_documents';

function General({
  form,
  setDocumentType,
}: {
  form: UseFormReturnType<any>;
  setDocumentType: React.Dispatch<any>;
}) {
  const { data: authorized_documents, isLoading: isAuthorizedLoading } = useAuthorizedDocuments();
  const [dropdownDocs, setDropdownDocs] = useState<any[]>([]);

  useEffect(() => {
    if (!isAuthorizedLoading && authorized_documents?.data) {
      const to_show = DOCUMENTTYPEOPTIONS;
      const numbers = authorized_documents.data.map((element) => element.dte_type);
      for (let i = 0; i < to_show.length; i++) {
        const doc_number = to_show[i]['number'];
        to_show[i]['disabled'] = numbers.includes(doc_number) ? false : true;
      }
      setDropdownDocs(to_show);
    }
  }, [isAuthorizedLoading, authorized_documents]);

  return (
    <Paper shadow="xl" p="lg">
      <Text fw={700} mb={10} fz={20}>
        Generales
      </Text>
      <Grid>
        {!form.values.document.id && (
          <Grid.Col span={{ base: 12, md: 3 }}>
            <Skeleton visible={isAuthorizedLoading}>
              <Select
                withAsterisk
                label="Tipo de documento"
                placeholder="Tipo de documento"
                data={dropdownDocs}
                searchable
                {...form.getInputProps('document.type')}
                value={form.values.document.type}
                onChange={(value) => {
                  form.setFieldValue('document.type', value);
                  setDocumentType(value);
                  form.clearErrors();
                }}
              />
            </Skeleton>
          </Grid.Col>
        )}
        <Grid.Col span={{ base: 12, md: 2 }}>
          <DateInput
            key="issue_date"
            withAsterisk
            valueFormat="YYYY-MM-DD"
            label="Fecha del documento"
            placeholder="Fecha"
            {...form.getInputProps('document.issue_date')}
          />
        </Grid.Col>
        {!regularNoteType(form.values.document.type) && <AdditionalFields form={form} />}
      </Grid>
    </Paper>
  );
}

export default General;

function AdditionalFields({ form }: { form: UseFormReturnType<any> }) {
  if (receiptType(form.values.document.type)) {
    return (
      <Grid.Col span={{ base: 12, md: 3 }}>
        <Select
          required
          key="receipt_service_indicator"
          label="Indicador de servicio"
          placeholder="Indicador de servicio"
          data={RECEIPTSERVICEINDICATOROPTIONS}
          {...form.getInputProps('document.service_indicator')}
          value={form.values.document.service_indicator}
          onChange={(value) => form.setFieldValue('document.service_indicator', value)}
        />
      </Grid.Col>
    );
  } else if (exportType(form.values.document.type)) {
    return (
      <>
        <Grid.Col span={{ base: 12, md: 3 }}>
          <Select
            key="exportation_service_indicator"
            label="Indicador de servicio"
            placeholder="Indicador de servicio"
            data={EXPORTATIONSERVICEINDICATOROPTIONS}
            {...form.getInputProps('document.service_indicator')}
            value={String(form.values.document.service_indicator)}
            onChange={(value) => form.setFieldValue('document.service_indicator', value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Select
            key="exportation_payment_type"
            label="Forma de Pago Exportación"
            placeholder="Forma de Pago Exportación"
            data={EXPORTATIONPAYMENTOPTIONS}
            {...form.getInputProps('document.exportation_payment_type')}
            value={String(form.values.document.exportation_payment_type)}
            onChange={(value) => form.setFieldValue('document.exportation_payment_type', value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3 }}>
          <Select
            key="currency"
            required
            searchable
            label="Tipo Moneda Transacción"
            placeholder="Tipo Moneda Transacción"
            data={CURRENCYOPTIONS}
            {...form.getInputProps('document.currency')}
            value={form.values.document.currency}
            onChange={(value) => form.setFieldValue('document.currency', value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 2 }}>
          <NumberInput
            key="exchange_rate"
            withAsterisk
            label="Tasa de Cambio"
            placeholder="Tasa de Cambio"
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            {...form.getInputProps(`document.exchange_rate`)}
          />
        </Grid.Col>
      </>
    );
  } else if (form.values.document.type === 'ElectronicDispatchGuide') {
    return (
      <>
        <Grid.Col span={{ base: 12, md: 3 }}>
          <Select
            key="dispatch_type"
            label="Tipo de Despacho"
            placeholder="Tipo de Despacho"
            data={DISPATCHTYPEPEOPTIONS}
            {...form.getInputProps('document.dispatch_type')}
            value={String(form.values.document.dispatch_type)}
            onChange={(value) => form.setFieldValue('document.dispatch_type', value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Select
            key="transfer_index"
            withAsterisk
            label="Indicador Tipo de Traslado"
            placeholder="Indicador Tipo de Traslado"
            data={TRANSFERINDEXOPTIONS}
            {...form.getInputProps('document.transfer_index')}
            value={String(form.values.document.transfer_index)}
            onChange={(value) => form.setFieldValue('document.transfer_index', value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3 }}>
          <Select
            key="transfer_code"
            searchable
            label="Código Emisor de Traslado Excep."
            placeholder="Código Emisor de Traslado Excep."
            data={TRANSFERCODEOPTIONS}
            {...form.getInputProps('document.transfer_code')}
            value={String(form.values.document.transfer_code)}
            onChange={(value) => form.setFieldValue('document.transfer_code', value)}
          />
        </Grid.Col>
      </>
    );
  } else {
    return (
      <>
        <Grid.Col span={{ base: 12, md: 2 }}>
          <Select
            withAsterisk
            required
            label="Forma de Pago"
            placeholder="Forma de Pago"
            data={PAYMENTTYPES}
            {...form.getInputProps('document.payment_type')}
            value={form.values.document.payment_type}
            onChange={(value) => form.setFieldValue('document.payment_type', value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3 }}>
          <Select
            withAsterisk
            required
            label="Tipo de Venta"
            placeholder="Tipo de Venta"
            data={KINDOFSALE}
            {...form.getInputProps('document.kind_of_sale')}
            value={form.values.document.kind_of_sale}
            onChange={(value) => form.setFieldValue('document.kind_of_sale', value)}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 2 }}>
          <Select
            label="Indicador de servicio"
            placeholder="Indicador de servicio"
            data={GENERALSERVICEINDICATOROPTIONS}
            {...form.getInputProps('document.service_indicator')}
            value={form.values.document.service_indicator}
            onChange={(value) => form.setFieldValue('document.service_indicator', value)}
          />
        </Grid.Col>
      </>
    );
  }
}
