import { Grid, Text, Skeleton } from '@mantine/core';

function Line({
  name,
  value,
  loading = false,
}: {
  name: string;
  value: string | undefined;
  loading?: boolean;
}) {
  return (
    <Grid>
      <Grid.Col span={6}>
        <Skeleton visible={loading}>
          <Text c="dimmed" fw={600}>
            {name}
          </Text>
        </Skeleton>
      </Grid.Col>
      <Grid.Col span={6}>
        <Skeleton visible={loading} style={{ height: '25%' }}>
          {value}
        </Skeleton>
      </Grid.Col>
    </Grid>
  );
}

export default Line;