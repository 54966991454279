import { useLogin } from '@api/auth';
import { useForm } from '@mantine/form';
import { Link } from 'react-router-dom';

import { Paper, TextInput, PasswordInput, Button, Title } from '@mantine/core';
import styles from './Login.module.scss';

const Login = () => {
  const form = useForm({
    initialValues: { email: '', password: '' },

    validate: {
      email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Correo Inválido'),
      password: (value: string) => (value.length < 2 ? 'Debes ingresar una contraseña' : null),
    },
  });

  const { mutate: AddLogin, isLoading } = useLogin();

  const handleSubmit = async (e: any) => {
    AddLogin(e);
  };
  return (
    <div className={styles.wrapper}>
      <Paper className={styles.form} radius={0} p={30}>
        <Title order={2} className={styles.title} ta="center" mt="md" mb={50}>
          Bienvenido a tufacturador.cl!
        </Title>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            required
            label="E-mail"
            placeholder="hola@tufacturador.cl"
            size="md"
            {...form.getInputProps('email')}
          />
          <PasswordInput
            label="Contraseña"
            placeholder="Tu Contraseña"
            mt="md"
            size="md"
            {...form.getInputProps('password')}
          />
          {/* <Checkbox label="Mantenerme Logueado" mt="xl" size="md" /> */}
          <Button type="submit" fullWidth mt="xl" size="md" disabled={isLoading}>
            Login
          </Button>
          <div className={styles.linkContainer}>
            <Link to="/recuperar-contraseña" className={styles.link}>
              Olvide mi contraseña
            </Link>
          </div>
        </form>
        {/* <Text ta="center" mt="md">
          No tienes una cuenta?{' '}
          <Anchor<'a'> href="#" fw={700} onClick={(event) => event.preventDefault()}>
            Registrarse
          </Anchor>
        </Text> */}
      </Paper>
    </div>
  );
};

export default Login;
