import { Grid, Text, Stack, Divider, NumberFormatter } from '@mantine/core';
import { CommonHash, Charge } from '@types';

function Charges({ document }: { document: CommonHash }) {
  return (
    <Stack mt={40} gap="0">
      <Grid>
        <Grid.Col span={2} py={0}>
          <Text fw={800} fz={12}>
            Com/Cargo
          </Text>
        </Grid.Col>
        <Grid.Col span={5} py={0}>
          <Text fw={800} fz={12}>
            Glosa
          </Text>
        </Grid.Col>
        <Grid.Col span={2.5} py={0}>
          <Text fw={800} fz={12}>
            Valor Neto
          </Text>
        </Grid.Col>
        <Grid.Col span={2.5} py={0}>
          <Text fw={800} fz={12} ps={10}>
            Valor Exento
          </Text>
        </Grid.Col>
      </Grid>
      <Divider color="black" my={10} />
      <Grid>
        {document?.charges_attributes.map((charge: Charge, index: number) => (
          <>
            <Grid.Col span={2} py={1}>
              <Text fz={12}>{index + 1}</Text>
            </Grid.Col>
            <Grid.Col span={5} py={1}>
              <Text fz={12}>{charge.description}</Text>
            </Grid.Col>
            <Grid.Col span={2.5} py={1}>
              <Text fz={12}>
                <NumberFormatter
                  thousandSeparator="."
                  decimalSeparator=","
                  value={charge.net_amount}
                />
              </Text>
            </Grid.Col>
            <Grid.Col span={2.5} py={1}>
              <Text fz={12}>
                <NumberFormatter
                  thousandSeparator="."
                  decimalSeparator=","
                  value={charge.exempt_amount}
                />
              </Text>
            </Grid.Col>
          </>
        ))}
      </Grid>
    </Stack>
  );
}

export default Charges;
