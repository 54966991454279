import { Grid, rem, Text, Paper, Stack, Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';

import {
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconAlertCircleFilled,
} from '@tabler/icons-react';
import styles from './Configuration.module.scss';
interface CompProps {
  link: string;
  icon: any;
  title: string;
  state?: 'success' | 'warning' | 'error';
  skeletonDisplay: boolean;
}

interface IconStateProps {
  state?: 'success' | 'warning' | 'error';
}

function IconState({ state }: IconStateProps) {
  if (state === 'success') {
    return (
      <IconCircleCheckFilled
        style={{
          color: 'var(--mantine-color-green-9)',
          width: rem(30),
          height: rem(30),
        }}
      />
    );
  } else if (state === 'warning') {
    return (
      <IconAlertCircleFilled
        style={{
          color: 'var(--mantine-color-yellow-9)',
          width: rem(30),
          height: rem(30),
        }}
      />
    );
  } else {
    return (
      <IconCircleXFilled
        style={{
          color: 'var(--mantine-color-red-9)',
          width: rem(30),
          height: rem(30),
        }}
      />
    );
  }
}

function ConfigurationCard({ link, icon, title, state, skeletonDisplay }: CompProps) {
  return (
    <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
      <Paper
        shadow="sm"
        withBorder
        component={Link}
        to={link}
        p={10}
        classNames={{
          root: styles.configurationCard,
        }}
      >
        <Skeleton visible={skeletonDisplay}>
          <Stack align="center" justify="center">
            {icon}
            <Text fz={20} fw={500}>
              {title}
            </Text>
            {state && <IconState state={state} />}
          </Stack>
        </Skeleton>
        {/* <Stack align="center" justify="center">
          {skeletonDisplay ? (
            <Skeleton />
          ) : (
            <>
              {icon}
              <Text fz={20} fw={500}>
                {title}
              </Text>
              {state && <IconState state={state} />}
            </>
          )}
        </Stack> */}
      </Paper>
    </Grid.Col>
  );
}

export default ConfigurationCard;
