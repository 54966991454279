import { Document } from '@types';
import { Text } from '@mantine/core';
import { REFERENCEDOCUMENTSOPTIONS, NOTEREASONS } from '@constants';
import styles from './ModalPrevisualization.module.scss';

function References({ document }: { document?: Document }) {
  const referenceName = (value: string) => {
    const reference = REFERENCEDOCUMENTSOPTIONS.find((reference) => reference.value === value);
    return reference ? reference.label.split('-')[1] : '';
  };
  const referenceString = () => {
    const references: string[] = [];
    document?.references.forEach((reference: any) => {
      const reason = NOTEREASONS.find((reason) => reason.value === reference.code)?.label;
      if (reference.global_index) {
        references.push(
          `${reason || ''} ${referenceName(reference.original_dte_type).toUpperCase()} ${
            reference.description
          }`,
        );
      } else {
        references.push(
          `${reason || ''} ${referenceName(reference.original_dte_type).toUpperCase()} FOLIO ${
            reference.original_number
          } DEL ${reference.date}`,
        );
      }
    });
    return references.join('; ');
  };
  return (
    <div className={styles.referenceBox}>
      <Text fz={13}>Referencia(s): {referenceString()}</Text>
    </div>
  );
}

export default References;
