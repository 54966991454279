import request from '@utils/axios-utils';
import { useQuery } from 'react-query';
import { ApiList, AuthorizedDocument } from '@types';
import { useAuth } from '@providers/auth';

export function useAuthorizedDocuments() {
  const { session } = useAuth();
  return useQuery<ApiList<AuthorizedDocument>>(
    ['authorized_documents'],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/users/authorized_documents`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}
