import { Modal, Stack, Text, Paper, Divider, Group, Checkbox, Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { AuthorizedDocument } from '@types';
import { IconFile } from '@tabler/icons-react';
import { useStampCafs } from '@api/cafs';

interface CompProps {
  opened: boolean;
  authorized_documents: AuthorizedDocument[];
  close: () => void;
}

function StampFoliosModal({ opened, close, authorized_documents }: CompProps) {
  const { mutate: stampCafs } = useStampCafs();
  const form = useForm({
    initialValues: { stamp_cafs: [] as number[] },
    validate: {
      stamp_cafs: (value) => {
        if (value.length === 0) {
          return 'Debes seleccionar al menos un documento';
        }
        return null;
      },
    },
  });

  return (
    <Modal.Root size="lg" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fz={20} fw={600}>
            Seleccione un documento para timbrar folios
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={form.onSubmit((values, event) => {
              event?.preventDefault();
              event?.stopPropagation();
              stampCafs({ data: values });
              form.reset();
              close();
            })}
          >
            <Stack align="center">
              <Paper withBorder px={20} w="100%">
                {authorized_documents.map((caf, index) => (
                  <div key={index}>
                    <Group py={6} justify="space-between" pe={10}>
                      <Group>
                        <IconFile />
                        <Text>{caf.document}</Text>
                      </Group>
                      <Checkbox
                        onChange={(event) => {
                          form.setFieldValue(
                            `stamp_cafs`,
                            event.currentTarget.checked
                              ? [...form.values.stamp_cafs, caf.dte_type]
                              : form.values.stamp_cafs.filter((c) => c !== caf.dte_type),
                          );
                        }}
                        checked={form.values.stamp_cafs.includes(caf.dte_type)}
                      />
                    </Group>
                    {authorized_documents.length !== index + 1 && <Divider />}
                  </div>
                ))}
              </Paper>
              <Button type="submit" w={200}>
                Timbrar
              </Button>
            </Stack>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

export default StampFoliosModal;
