import Routes from '@pages/routes';
import AuthProvider from '@providers/auth';
import FiltersProvider from '@providers/filters';
import NavbarProvider from '@providers/navbar';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import '@mantine/core/styles.css';
import { MantineProvider } from '@mantine/core';
import { theme } from './theme';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
import { Notifications } from '@mantine/notifications';
import { AxiosInterceptor } from '@utils/axios-utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <MantineProvider theme={theme}>
      <Notifications position="top-right" />
      <AuthProvider>
        <FiltersProvider>
          <NavbarProvider>
            <QueryClientProvider client={queryClient}>
              <AxiosInterceptor>
                <Routes />
                <ReactQueryDevtools initialIsOpen={true} />
              </AxiosInterceptor>
            </QueryClientProvider>
          </NavbarProvider>
        </FiltersProvider>
      </AuthProvider>
    </MantineProvider>
  );
}

export default App;
