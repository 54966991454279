/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import {
  Stack,
  Group,
  TextInput,
  Paper,
  Text,
  Button,
  Grid,
  Select,
  NumberInput,
  Checkbox,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import styles from '@pages/authenticated/documents/Create.module.scss';
import { receiptType, exemptType, calculateLineTotal } from './formUtils';
import { CommonHash, Line } from '@types';
import { BILLINGINDICATOROPTIONS, SETTLEMENTLINEOPTIONS } from '@constants';
import LineSettings from './LineSettings';

function Lines({ form }: { form: UseFormReturnType<any> }) {
  const initialLinesAmount =
    form.values.document.lines_attributes.filter((lineInstance: Line) => !lineInstance._destroy)
      .length || 1;

  const [linesAmount, setLinesAmount] = useState(initialLinesAmount);
  const lines = Array.from({ length: linesAmount }, (_, index) => {
    if (!form.values.document.lines_attributes[index]?._destroy)
      return <LineInstance key={index} index={index} form={form} />;
  });

  useEffect(() => {
    setLinesAmount(form.values.document.lines_attributes.length);
  }, [form.values.document.lines_attributes]);
  const handleAddLine = () => {
    form.insertListItem('document.lines_attributes', {
      code: '',
      code_type: '',
      name: '',
      description: '',
      quantity: '',
      amount: '',
      discount: '',
      total_amount: '',
      billing_indicator: 0,
      reference_kind: '',
      _destroy: false,
    });
    setLinesAmount((prevValue: number) => prevValue + 1);
  };

  const handleRemoveLine = () => {
    if (form.values.document.lines_attributes[linesAmount - 1].id) {
      form.setFieldValue(`document.lines_attributes.${linesAmount - 1}._destroy`, true);
    } else {
      form.removeListItem('document.lines_attributes', linesAmount - 1);
    }
    setLinesAmount((prevValue: number) => prevValue - 1);
  };
  return (
    <Paper shadow="xl" p="lg">
      <Text fw={700} mb={10} fz={20}>
        Detalles
      </Text>
      <Stack>{lines}</Stack>
      <Group justify="center" mt={10}>
        <Button className={styles.addBtn} onClick={handleAddLine}>
          <IconPlus />
        </Button>
        {linesAmount > 1 && (
          <Button className={styles.addBtn} onClick={handleRemoveLine} color="red">
            <IconMinus />
          </Button>
        )}
      </Group>
    </Paper>
  );
}

function LineInstance({ index, form }: { index: number; form: UseFormReturnType<any> }) {
  const line: Line | undefined = form.values.document.lines_attributes[index];
  const [settings, setSettings] = useState([] as CommonHash[]);
  const amountText = () => {
    if (receiptType(form.values.document.type) && !exemptType(form.values.document.type))
      return 'Precio con impuesto';
    return 'Precio';
  };

  const subTotalText = () => {
    if (receiptType(form.values.document.type) && !exemptType(form.values.document.type))
      return 'Subtotal con impuesto';
    return 'Subtotal';
  };

  useEffect(() => {
    if (line) {
      const floatResult = calculateLineTotal(line.amount, line.quantity, line.discount);
      form.setFieldValue(
        `document.lines_attributes.${index}.total_amount`,
        String(floatResult || ''),
      );
    }
  }, [
    form.values.document.lines_attributes[index]?.quantity,
    form.values.document.lines_attributes[index]?.amount,
    form.values.document.lines_attributes[index]?.discount,
  ]);

  useEffect(() => {
    const initialSettings: CommonHash[] = [
      {
        label: 'Descripción',
        value: line?.description !== null && line?.description !== '',
      },
      {
        label: 'Cód. Producto',
        value: line?.code !== null && line?.code !== '',
      },
      ...(form.values.document.type !== 'ElectronicSettlement'
        ? [
            {
              label: 'Indicador Facturación',
              value: line?.billing_indicator !== null && line?.billing_indicator !== 0,
            },
          ]
        : []),
    ];
    setSettings(initialSettings);
  }, [form.values.document.type]);
  return (
    <Grid>
      {settings.find((s) => s.label === 'Cód. Producto')?.value && (
        <>
          <Grid.Col span={1}>
            <TextInput
              label="Tipo"
              placeholder="Tipo Cód."
              maxLength={10}
              {...form.getInputProps(`document.lines_attributes.${index}.code_type`)}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <TextInput
              label="Código"
              placeholder="Código"
              maxLength={35}
              {...form.getInputProps(`document.lines_attributes.${index}.code`)}
            />
          </Grid.Col>
        </>
      )}
      <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
        <TextInput
          withAsterisk
          label="Nombre"
          placeholder="Nombre"
          maxLength={80}
          {...form.getInputProps(`document.lines_attributes.${index}.name`)}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
        <NumberInput
          withAsterisk
          label="Cantidad"
          placeholder="Cantidad"
          decimalScale={2}
          decimalSeparator=","
          thousandSeparator="."
          {...form.getInputProps(`document.lines_attributes.${index}.quantity`)}
        />
      </Grid.Col>
      {form.values.document.type !== 'ElectronicSettlement' ? (
        <>
          <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
            <NumberInput
              withAsterisk
              label={amountText()}
              placeholder="Precio"
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              {...form.getInputProps(`document.lines_attributes.${index}.amount`)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 2 }}>
            <NumberInput
              label="Descuento"
              placeholder="Dcto"
              suffix="%"
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              {...form.getInputProps(`document.lines_attributes.${index}.discount`)}
            />
          </Grid.Col>

          <Grid.Col span="auto">
            <NumberInput
              label={subTotalText()}
              placeholder="SubTotal"
              hideControls
              decimalSeparator=","
              thousandSeparator="."
              disabled={true}
              {...form.getInputProps(`document.lines_attributes.${index}.total_amount`)}
            />
          </Grid.Col>
          <Grid.Col span="content" className={styles.settingCol}>
            <LineSettings
              settings={settings}
              setSettings={setSettings}
              lineIndex={index}
              form={form}
            />
          </Grid.Col>
          {settings.find((s) => s.label === 'Indicador Facturación')?.value && (
            <Grid.Col span={{ base: 12, md: 3 }}>
              <Select
                label="Indicador Facturación/Exención"
                placeholder="Indicador Facturación/Exención"
                searchable
                data={BILLINGINDICATOROPTIONS}
                {...form.getInputProps(`document.lines_attributes.${index}.billing_indicator`)}
                value={String(line?.billing_indicator || '')}
                onChange={(value) => {
                  form.setFieldValue(`document.lines_attributes.${index}.billing_indicator`, value);
                  if ([32, 34, 38, 41].includes(parseInt(value || ''))) {
                    form.setFieldValue(`document.lines_attributes.${index}.billing_indicator`, 1);
                  }
                }}
              />
            </Grid.Col>
          )}
          {settings.find((s) => s.label === 'Descripción')?.value && (
            <Grid.Col
              span={settings.find((s) => s.label === 'Indicador Facturación')!.value ? 'auto' : 12}
            >
              <TextInput
                label="Descripción"
                placeholder="Descripción"
                maxLength={500}
                {...form.getInputProps(`document.lines_attributes.${index}.description`)}
              />
            </Grid.Col>
          )}
        </>
      ) : (
        <>
          <Grid.Col span={{ base: 12, md: 3 }}>
            <Select
              label="Tipo doc. que se Liquida"
              placeholder="Tipo doc. que se Liquida"
              searchable
              data={SETTLEMENTLINEOPTIONS}
              {...form.getInputProps(`document.lines_attributes.${index}.reference_kind`)}
              value={String(line?.reference_kind || '')}
              onChange={(value) => {
                form.setFieldValue(`document.lines_attributes.${index}.reference_kind`, value);
                if ([32, 34, 38, 41].includes(parseInt(value || ''))) {
                  form.setFieldValue(`document.lines_attributes.${index}.billing_indicator`, 1);
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 'content' }}>
            <Text
              fw={500}
              mt={2}
              style={{
                fontSize: 'var(--input-label-size, var(--mantine-font-size-sm))',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Exento
            </Text>
            <Checkbox
              {...form.getInputProps(`document.lines_attributes.${index}.billing_indicator`)}
              mt={5}
              size="md"
              checked={line?.billing_indicator === 1}
              onChange={() => {
                form.setFieldValue(
                  `document.lines_attributes.${index}.billing_indicator`,
                  line?.billing_indicator === 1 ? 0 : 1,
                );
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 'auto' }}>
            <NumberInput
              withAsterisk
              label="Total Linea"
              placeholder="Total Linea"
              allowDecimal={false}
              decimalSeparator=","
              thousandSeparator="."
              {...form.getInputProps(`document.lines_attributes.${index}.total_amount`)}
              value={line?.total_amount}
            />
          </Grid.Col>
          <Grid.Col span="content" className={styles.settingCol}>
            <LineSettings
              settings={settings}
              setSettings={setSettings}
              lineIndex={index}
              form={form}
            />
          </Grid.Col>
          {settings.find((s) => s.label === 'Descripción')?.value && (
            <Grid.Col span={12}>
              <TextInput
                label="Descripción"
                placeholder="Descripción"
                maxLength={500}
                {...form.getInputProps(`document.lines_attributes.${index}.description`)}
              />
            </Grid.Col>
          )}
        </>
      )}
    </Grid>
  );
}

export default Lines;
