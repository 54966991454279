import request from '@utils/axios-utils';
import { useQuery } from 'react-query';
import { ApiList, Commune } from '@types';
import { useAuth } from '@providers/auth';

export function useCommunes() {
  const { session } = useAuth();
  return useQuery<ApiList<Commune>>(
    ['communes'],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/communes`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}

export function useCommunesUnauthenticated() {
  return useQuery<ApiList<Commune>>(['communes'], async () => {
    const response = await request({
      url: `/communes/unauthenticated`,
      method: 'GET',
    });
    return response.data;
  });
}
