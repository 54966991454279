import { useEffect } from 'react';
import { Modal, Stack, Text, Group, Button, List, Input } from '@mantine/core';
import DropzoneComponent from '@components/Dropzone';
import { useUploadLogo } from '@api/accounts';
import { useForm } from '@mantine/form';

interface CompProps {
  opened: boolean;
  close: () => void;
}
interface FormProps {
  file: File | null;
}
function ListComponent() {
  const listInfo = [
    {
      value: 'Imagen debe ser de 100x100.',
    },
    {
      value: 'Extensión del archivo puede ser .png, .jpg, .jpeg.',
    },
  ];
  const items = listInfo.map((info, index) => (
    <List.Item c="dimmed" key={index}>
      {info.value}
    </List.Item>
  ));
  return <List ps={20}>{items}</List>;
}

function UploadLogoModal({ opened, close }: CompProps) {
  const { mutate: UploadLogo, isSuccess, isError, isLoading } = useUploadLogo();
  const allowExtensions = ['image/jpeg', 'image/png'];

  const form = useForm<FormProps>({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      file: null,
    },

    validate: {
      file: (value) => (value !== null ? null : 'Debe seleccionar un archivo'),
    },
  });

  useEffect(() => {
    close();
  }, [isSuccess, isError]);
  return (
    <Modal.Root size="xl" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content py={20} px={20}>
        <Modal.Header>
          <Modal.Title fz={20} fw={700}>
            Logo
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Text fw={600} my={8}>
            Requisitos
          </Text>
          <ListComponent />
          <form
            onSubmit={form.onSubmit((values, event) => {
              event?.preventDefault();
              event?.stopPropagation();
              const formData = new FormData();
              formData.append('file', values.file as File);
              UploadLogo({ data: formData });
              form.reset();
            })}
          >
            <Stack align="center" mt={15}>
              <Input
                renderRoot={(props) => (
                  <>
                    <DropzoneComponent
                      form={form}
                      fieldName="file"
                      unique
                      allowExtensions={allowExtensions}
                      {...props}
                    />
                    <Input.Error mt={5}>{form.errors.file}</Input.Error>
                  </>
                )}
              />
            </Stack>
            <Group justify="center" mt={20}>
              <Button w={200} type="submit" loading={isLoading} disabled={isLoading}>
                Subir
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

export default UploadLogoModal;
