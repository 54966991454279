import { Modal, Button, Stack, Input } from '@mantine/core';
import { UseMutateFunction } from 'react-query';
import { useForm } from '@mantine/form';
import { CommonHash } from '@types';
import DropzoneComponent from '@components/Dropzone';

interface CompProps {
  opened: boolean;
  close: () => void;
  mutate: UseMutateFunction<any, unknown, CommonHash, unknown>;
}

interface FormProps {
  files: File[];
}

function DropzoneModal({ opened, close, mutate }: CompProps) {
  const allowExtensions = ['application/xml', 'text/xml'];
  const form = useForm<FormProps>({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      files: [],
    },

    validate: {
      files: (value) => (value.length < 1 ? 'Debe seleccionar al menos un archivo' : null),
    },
  });
  return (
    <Modal.Root size="md" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fz={20} fw={600}>
            Cargar Folios
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={form.onSubmit((values, event) => {
              event?.preventDefault();
              event?.stopPropagation();
              const formData = new FormData();
              values.files.map((file) => formData.append(`files[]`, file));
              mutate({ data: formData });
              close();
              form.reset();
            })}
          >
            <Stack align="end">
              <Input
                renderRoot={(props) => (
                  <>
                    <DropzoneComponent
                      form={form}
                      fieldName="files"
                      allowExtensions={allowExtensions}
                      {...props}
                    />
                    <Input.Error mt={5}>{form.errors.files}</Input.Error>
                  </>
                )}
              />
              <Button type="submit">Listo</Button>
            </Stack>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

export default DropzoneModal;
