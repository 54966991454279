import { Modal, Button, Stack, Text, Group, rem } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

interface CompProps {
  apiFunction: ((id?: number) => void) | (() => void);
  opened: boolean;
  id?: number;
  close: () => void;
  redirectUrl?: string;
}

function ConfirmationModal({ apiFunction, id, opened, close, redirectUrl }: CompProps) {
  const navigate = useNavigate();
  const handleDelete = () => {
    if (id) {
      apiFunction(id);
    } else {
      apiFunction();
    }
    close();
    if (redirectUrl) navigate(redirectUrl);
  };
  return (
    <Modal.Root size="sm" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack justify="center" align="center">
            <IconAlertTriangle
              style={{ width: rem(100), height: rem(100), color: 'var(--mantine-color-red-8)' }}
            />
            <Text fz={25} fw={600}>
              Estás seguro?
            </Text>
            <Text fz={18}>Esta acción no se puede deshacer</Text>
            <Group>
              <Button className="delete-button" onClick={handleDelete}>
                Si, eliminar
              </Button>
              <Button className="delete-outline-button" onClick={() => close()}>
                Cancelar
              </Button>
            </Group>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

export default ConfirmationModal;
