import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { Title, Pagination, Group, Button, Paper } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { TableSort } from '@components/TableSort/TableSort';
import { useDocuments, useBookDocument } from '@api/documents';
import FilterButton from '@pages/authenticated/documents/FilterButton';
import FilterModal from './FilterModal';
import { useFilters } from '@providers/filters';

const Received = () => {
  const { filters } = useFilters();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialPage = Number(searchParams.get('page')) || 1;
  const [activePage, setPage] = useState(initialPage);
  const [direction, setDirection] = useState('DESC');
  const [sortKey, setSortKey] = useState('issue_date');
  const startDate =
    filters.start_date instanceof Date ? dayjs(filters.start_date).format('YYYY-MM-DD') : null;
  const endDate =
    filters.end_date instanceof Date ? dayjs(filters.end_date).format('YYYY-MM-DD') : null;
  const { data: documents, isFetching } = useDocuments(
    'received',
    startDate,
    endDate,
    filters.tax_id,
    filters.legal_name,
    filters.document_type,
    filters.status,
    filters.code,
    filters.number,
    activePage,
    sortKey,
    direction,
  );

  const [openedFilterModal, { open: openFilterModal, close: closeFilterModal }] =
    useDisclosure(false);

  const { refetch } = useBookDocument(
    filters.start_date instanceof Date
      ? dayjs(filters.start_date).startOf('month').format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    filters.end_date instanceof Date
      ? dayjs(filters.end_date).endOf('month').format('YYYY-MM-DD')
      : dayjs().endOf('month').format('YYYY-MM-DD'),
    'purchases',
  );

  useEffect(() => {
    if (activePage === 1) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    } else {
      setSearchParams({ page: String(activePage) });
    }
  }, [activePage, setSearchParams, searchParams]);

  return (
    <>
      <Title order={1}>Documentos Recibidos</Title>
      <Group justify="space-between" mt={10} mb={20}>
        <FilterButton open={openFilterModal} />
        <Paper shadow="sm">
          <Button onClick={() => refetch()}>Descargar Libro de Compras</Button>
        </Paper>
      </Group>
      <TableSort
        docsProps={documents?.data}
        isFetching={isFetching}
        kind="received"
        sortKey={sortKey}
        direction={direction}
        setSortKey={setSortKey}
        setDirection={setDirection}
      />
      <FilterModal opened={openedFilterModal} close={closeFilterModal} setPage={setPage} />
      <div className="d-flex justify-content-center">
        <Pagination
          total={documents?.meta.pages || 1}
          value={activePage}
          onChange={setPage}
          mt="sm"
          pb={40}
        />
      </div>
    </>
  );
};

export default Received;
