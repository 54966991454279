import { useNewUserPassword } from '@api/users';
import { useForm, matchesField } from '@mantine/form';
import { Link, useParams } from 'react-router-dom';
import { Paper, PasswordInput, Button, Title } from '@mantine/core';
import styles from './Login.module.scss';
const NewUser = () => {
  const { token } = useParams<{ token?: string }>();
  const form = useForm({
    initialValues: { password: '', confirmPassword: '' },
    validate: {
      password: (value: string) => (value.length < 2 ? 'Debes ingresar una contraseña' : null),
      confirmPassword: matchesField('password', 'Las contraseñas no coinciden'),
    },
  });

  const { mutate: NewUserPassword, isLoading } = useNewUserPassword();

  const handleSubmit = async (e: any) => {
    NewUserPassword({ ...e, token });
  };
  return (
    <div className={styles.wrapper}>
      <Paper className={styles.form} radius={0} p={30}>
        <Title order={2} className={styles.title} ta="center" mt="md" mb={50}>
          Bienvenido!
        </Title>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <PasswordInput
            required
            label="Contraseña"
            placeholder="Contraseña"
            size="md"
            {...form.getInputProps('password')}
          />
          <PasswordInput
            required
            label="Confirma tu contraseña"
            placeholder="Confirma tu contraseña"
            size="md"
            mt={10}
            {...form.getInputProps('confirmPassword')}
          />
          <Button type="submit" fullWidth mt="xl" size="md" disabled={isLoading}>
            Crear
          </Button>
          <div className={styles.linkContainer}>
            <Link to="/login" className={styles.link}>
              Iniciar sesión
            </Link>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default NewUser;
