import { Modal, Stack, rem, Text } from '@mantine/core';
import { IconPhotoQuestion } from '@tabler/icons-react';

interface CompProps {
  opened: boolean;
  close: () => void;
}

function NoPdfModal({ opened, close }: CompProps) {
  return (
    <Modal.Root size="lg" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack justify="center" align="center" px={30} pb={10}>
            <IconPhotoQuestion
              style={{ width: rem(150), height: rem(150) }}
              stroke={1.5}
              color="var(--mantine-primary-color-filled)"
            />
            <Text fz={20}>
              La factura recibida no fue enviada a la casilla de correo, por lo que el PDF no puede
              ser generado.
            </Text>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

export default NoPdfModal;
