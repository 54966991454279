import { useContext } from 'react';
import { AuthContext } from '@providers/auth';
import { Overlay, Paper, Loader, Text } from '@mantine/core';
import { ProtectedRoute } from '@components/routes/protected';
import styles from './Layout.module.scss';

function Layout() {
  const { session } = useContext(AuthContext);

  if (session.status === 'loading') return <LoaderScreen />;
  return <ProtectedRoute />;
}

export default Layout;

function LoaderScreen() {
  return (
    <div className="">
      <Overlay color="#000" backgroundOpacity={0.3} zIndex={400} />
      <Paper shadow="sm" className={styles.loadingPaper}>
        <Loader color="var(--mantine-color-gray-5)" />
        <Text fz={14} c="var(--mantine-color-gray-5)">
          Cargando...
        </Text>
      </Paper>
    </div>
  );
}
