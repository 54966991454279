import {
  Badge,
  HoverCard,
  Text,
  rem,
} from '@mantine/core';

import { Link } from 'react-router-dom';
import { CommonHash } from '@types';
import styles from './BadgeWithHoverCard.module.scss';

import { IconInfoCircle } from '@tabler/icons-react'

function noteDocumentMessage(row: CommonHash) {
  if (!row.note_document?.number) return 'Anulado';
  if (row.sii_status === 'canceled')
    return `Anulado (N${row.dte_type === 'NOTA DE CRÉDITO ELECTRÓNICA' ? 'D' : 'C'} N° ${row.note_document.number})`;
  return `Corregido (N${row.note_document.type === 'Documents::ElectronicCreditNote' ? 'C' : 'D'} N° ${row.note_document.number})`;
}

function getStatusDetails(status: string, sii_status: string | null): { color: string, message: string } {
  if (status === 'draft') {
    return { color: 'yellow', message: 'Borrador' };
  }

  if (sii_status === null && status === 'sent') {
    return { color: 'blue', message: 'Enviandose al SII' };
  }

  switch (sii_status) {
    case 'sii_rejected':
      return { color: 'red', message: 'Rechazado por SII' };
    case 'sii_accepted':
      return { color: 'green', message: 'Aceptado por SII' };
    case 'sii_accepted_with_warnings':
      return { color: 'yellow', message: 'Aceptado con observaciones por SII' };
    case 'sii_waiting':
      return { color: 'yellow', message: 'Esperando respuesta SII' };
    case 'company_received':
      return { color: 'blue', message: 'Recibido por receptor' };
    case 'company_accepted':
      return { color: 'green', message: 'Aceptado por receptor' };
    case 'company_not_received':
      return { color: 'red', message: 'No recibido por receptor' };
    case 'company_rejected':
      return { color: 'red', message: 'Rechazado por receptor' };
    case 'canceled':
      return { color: 'grey', message: 'Anulado' };
    case 'auto_accepted':
      if (status === 'sent') {
        return { color: 'blue', message: 'Autoaceptado por receptor' };
      } else if (status === 'received') {
        return { color: 'blue', message: 'Autoaceptado' };
      }
      break;
  }
  return { color: 'grey', message: 'Otro' };
};

export function BadgeWithHoverCard({ row }: { row: CommonHash }) {

  const { color, message } = getStatusDetails(row.status, row.sii_status);
  let link_message = noteDocumentMessage(row);

  return (
    <>
      <div className={styles.statusContainer}>
        <HoverCard width={280} shadow="md">
          <HoverCard.Target>
            <div className={styles.statusContainer}>
              <Badge color={color}>
                {((row.sii_status === 'canceled' || row.status === 'corrected') && link_message) ? (
                  <Link to={row.note_document.link} className={styles.link} target="_blank">
                    {link_message}
                  </Link>
                ) : (
                  message
                )}
              </Badge>
              {row.sii_response_message && (
                <IconInfoCircle
                  style={{ width: rem(16), height: rem(16), color: 'var(--mantine-color-grey-4)' }}
                />
              )}
            </div>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text size="sm" >
              <Text ta="center" c={color} fw={700} >{message}</Text>
              {row.sii_response_message && <Text span > {row.sii_response_message}</Text>}
            </Text>
          </HoverCard.Dropdown>
        </HoverCard>
      </div>
    </>
  );
}