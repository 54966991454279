import { Grid, Text, Stack, Group, Divider } from '@mantine/core';
import { format } from 'rut.js';
import styles from './ModalPrevisualization.module.scss';
import { Transport as TransportType } from '@types';

function Transport({ transport }: { transport: TransportType }) {
  return (
    <Stack mt={30} gap="0">
      <Grid>
        <Grid.Col span={12} py={0} ta="center">
          <Text fw={800} fz={12}>
            Transporte
          </Text>
        </Grid.Col>
      </Grid>
      <Divider color="black" my={10} />
      <Row label="Patente" value={transport?.license_plate} />
      <Row label="R.U.T Transportista" value={format(transport?.driver_company_tax_id)} />
      <Row label="R.U.T Chofer" value={format(transport?.driver_tax_id)} />
      <Row label="Nombre Chofer" value={transport?.driver_name} />
    </Stack>
  );
}

function Row({ label, value }: { label: string; value: string }) {
  return (
    <Group>
      <div className={styles.transportLabel}>
        <Text fw={800} fz={9}>
          {label}
        </Text>
      </div>
      <div>
        <Text fz={9}>: {value}</Text>
      </div>
    </Group>
  );
}
export default Transport;
