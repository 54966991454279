import { useState } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { Company, Account, Transport as TransportType } from '@types';
import styles from './DrawerPrevisualization.module.scss';
import { Paper, Button } from '@mantine/core';
import Header from './Header';
import Receiver from './Receiver';
import References from './References';
import Lines from './Lines';
import Totals from './Totals';
import { useNavbar } from '@providers/navbar';
import Packages from './Packages';
import Transport from './Transport';
import Charges from './Charges';

function Previsualization({
  form,
  account,
  close,
  company,
}: {
  form: UseFormReturnType<any>;
  account?: Account;
  close: () => void;
  company: Company;
}) {
  const [hovered, setHovered] = useState(false);
  const { toggleNavbar } = useNavbar();
  const handleClose = () => {
    close();
    toggleNavbar();
  };

  return (
    <Paper
      className={styles.previsualizationContainer}
      shadow="sm"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Header form={form} account={account} />
      <Receiver company={company} document={form.values.document} />
      {form.values.document.references_attributes.length > 0 && <References form={form} />}
      {form.values.document.transport_attributes?.total_packages && (
        <Packages document={form.values.document} />
      )}
      <Lines document={form.values.document} />
      {form.values.document.charges_attributes.length > 0 && (
        <Charges document={form.values.document} />
      )}
      {showTransport(form.values.document.transport_attributes) && (
        <Transport transport={form.values.document.transport_attributes} />
      )}
      <Totals form={form} />
      <div className={styles.transitionContainer}>
        <Button
          display={hovered ? 'block' : 'block'}
          onClick={handleClose}
          w={150}
          className={styles.zoomButton}
        >
          Cerrar
        </Button>
      </div>
    </Paper>
  );
}

function showTransport(transport?: TransportType) {
  return (
    transport?.license_plate ||
    transport?.driver_company_tax_id ||
    transport?.driver_tax_id ||
    transport?.driver_name
  );
}

export default Previsualization;
