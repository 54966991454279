import { Grid, Stack, Text, Select, Paper, Skeleton, rem } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { Account } from '@types';
import { useAuth } from '@providers/auth';
import styles from '@pages/authenticated/documents/Create.module.scss';
import { transformDTETypeForLabel } from './formUtils';

function Emitter({
  form,
  account,
  readyToShow,
}: {
  form: UseFormReturnType<any>;
  account?: Account;
  readyToShow?: boolean;
}) {
  const { session } = useAuth();
  const logoUrl = session.activeAccount?.logo;
  return (
    <Paper shadow="xl" p="lg">
      <Grid justify="center">
        <Grid.Col span={{ base: 5, md: 4 }} order={{ base: 1, md: 1 }} className={styles.col}>
          {logoUrl && (
            <>
              <img src={logoUrl} alt="logo" className={styles.logoContainer} />
              <div></div>
            </>
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4 }} order={{ base: 3, md: 2 }}>
          {!readyToShow && <Skeleton visible={true} height={rem(150)} />}
          {readyToShow && <EmitterDetails emitter={account} form={form} />}
        </Grid.Col>
        <Grid.Col span={{ base: 7, md: 4 }} order={{ base: 2, md: 3 }} className={styles.col}>
          <div className={styles.redContainer}>
            <Text fw={400}>Rut {session.activeAccount?.tax_id}</Text>
            <Text fw={700}>{transformDTETypeForLabel(form.values.document.type)}</Text>
            <Text>N° folio no asignado</Text>
          </div>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}

function EmitterDetails({ emitter, form }: { emitter?: Account; form: UseFormReturnType<any> }) {
  const activities = emitter?.activities.map((a) => ({
    label: a.name,
    value: a.sii_code,
  }));
  const locations = emitter?.locations.map((a) => a.address);
  return (
    <Stack justify="center" gap={0}>
      <Text fw={800} fz={20}>
        {emitter?.legal_name}
      </Text>
      <Select
        variant="unstyled"
        placeholder="Select placeholder"
        data={activities}
        value={form.values.account.account_activity}
        onChange={(value) => form.setFieldValue('account.account_activity', value)}
      />
      <Select
        variant="unstyled"
        placeholder="Select placeholder"
        data={locations}
        value={form.values.account.account_address}
        onChange={(value) => form.setFieldValue('account.account_address', value)}
      />
      <Text>Email: {emitter?.contact_email}</Text>
      <Text>Teléfono: {emitter?.phone_number}</Text>
    </Stack>
  );
}

export default Emitter;
