import { Grid, Text, Stack, Divider, NumberFormatter } from '@mantine/core';
import { Document, Line } from '@types';
import { calculateLineDiscount } from '../../form/formUtils';
import { REFERENCEDOCUMENTSOPTIONS } from '@constants';
import styles from './ModalPrevisualization.module.scss';

function Lines({ document }: { document?: Document }) {
  return (
    <Stack mt={40} gap="0">
      <Grid>
        <Grid.Col span={1} py={0}>
          <Text fw={800} fz={9}>
            Item
          </Text>
        </Grid.Col>
        <Grid.Col span={3} py={0}>
          <Text fw={800} fz={9}>
            Detalle
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={0}>
          <Text fw={800} fz={9}>
            Cantidad
          </Text>
        </Grid.Col>
        {document?.type.split('::')[1] !== 'ElectronicSettlement' ? (
          <>
            <Grid.Col span={2} py={0}>
              <Text fw={800} fz={12}>
                Precio $
              </Text>
            </Grid.Col>
            <Grid.Col span={2} py={0}>
              <Text fw={800} fz={12}>
                Dcto $
              </Text>
            </Grid.Col>
          </>
        ) : (
          <Grid.Col span={4} py={0}>
            <Text fw={800} fz={9}>
              Tipo doc.
            </Text>
          </Grid.Col>
        )}
        <Grid.Col span={2} py={0}>
          <Text fw={800} fz={9}>
            Monto $
          </Text>
        </Grid.Col>
      </Grid>
      <Divider color="black" my={10} />
      <Grid>
        {document?.lines.map((line: Line, index: number) => {
          const discount = calculateLineDiscount(line.amount, line.quantity, line.discount) || '';
          const code = () => {
            let text = '';
            if (line.code_type !== null && line.code_type !== undefined && line.code_type !== '') {
              text += `${line.code_type}-`;
            }
            if (line.code !== null && line.code !== undefined && line.code !== '') {
              text += line.code;
            }
            return text;
          };
          return (
            <>
              <Grid.Col span={1} py={0}>
                <Text fz={9}>
                  {' '}
                  {line.code !== null && line.code !== undefined && line.code !== ''
                    ? code()
                    : index + 1}
                </Text>
              </Grid.Col>
              <Grid.Col span={3} py={0}>
                <Text fz={9}>{line.name}</Text>
                <Text fz={7} className={styles.description}>
                  {line.description}
                </Text>
              </Grid.Col>
              <Grid.Col span={2} py={0}>
                <Text fz={9}>
                  <NumberFormatter
                    thousandSeparator="."
                    decimalSeparator=","
                    value={line.quantity}
                  />
                </Text>
              </Grid.Col>
              {document.type.split('::')[1] !== 'ElectronicSettlement' ? (
                <>
                  <Grid.Col span={2} py={0}>
                    <Text fz={9}>
                      <NumberFormatter
                        thousandSeparator="."
                        decimalSeparator=","
                        value={line.amount}
                      />
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2} py={0}>
                    <Text fz={9}>
                      <NumberFormatter
                        thousandSeparator="."
                        decimalSeparator=","
                        value={discount}
                      />
                    </Text>
                  </Grid.Col>
                </>
              ) : (
                <Grid.Col span={4} py={0}>
                  <Text fz={9}>
                    {' '}
                    {REFERENCEDOCUMENTSOPTIONS.find(
                      (option) => option.value === line.reference_kind,
                    )?.label.split('-')[1] || (line.reference_kind as string)}
                  </Text>
                </Grid.Col>
              )}
              <Grid.Col span={2} py={0}>
                <Text fz={9}>
                  <NumberFormatter
                    thousandSeparator="."
                    decimalSeparator=","
                    value={line.total_amount}
                  />
                </Text>
              </Grid.Col>
            </>
          );
        })}
      </Grid>
    </Stack>
  );
}

export default Lines;
