import { Container, Anchor, rem, Text, Group, Paper, Stack, Button, Image } from '@mantine/core';
import { IconChevronLeft, IconPhotoOff } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useDeleteLogo } from '@api/accounts';
import UploadLogoModal from './UploadLogoModal';
import { useAuth } from '@providers/auth';
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal';

function Show() {
  const { session } = useAuth();
  const logo = session.activeAccount?.logo;
  const { mutate: deleteLogo } = useDeleteLogo();
  const [openedUploadLogoModal, { open: openUploadLogoModal, close: closeUploadLogoModal }] =
    useDisclosure(false);
  const [openedConfirmationModal, { open: openConfirmationModal, close: closeConfirmationModal }] =
    useDisclosure(false);
  return (
    <Container fluid>
      <Group>
        <Anchor
          href="/configuracion"
          underline="never"
          c="dimmed"
          className="d-flex align-items-center"
          fw={700}
        >
          <IconChevronLeft
            style={{
              width: rem(20),
              height: rem(20),
              color: 'var(--mantine-color-dimmed)',
              marginRight: 10,
            }}
          />
          Configuración
        </Anchor>
      </Group>
      <Text fz={28} fw={700}>
        Logo
      </Text>
      <Stack align="center" mt={50}>
        <Paper shadow="xs" px={15} py={20} withBorder>
          <Stack px={30} gap={0} align="center">
            {logo === undefined || '' || null ? (
              <IconPhotoOff width={150} height={150} color="gray" />
            ) : (
              <Image src={logo} alt="Logo" width={100} height={100} />
            )}
            <Group justify="flex-end" align="center" mt={20}>
              <Button onClick={openUploadLogoModal}>
                {logo === undefined || '' || null ? 'Subir Logo' : 'Cambiar logo'}
              </Button>
              <Button onClick={openConfirmationModal}>Eliminar</Button>
            </Group>
          </Stack>
        </Paper>
      </Stack>
      <UploadLogoModal opened={openedUploadLogoModal} close={closeUploadLogoModal} />
      <ConfirmationModal
        opened={openedConfirmationModal}
        close={closeConfirmationModal}
        apiFunction={deleteLogo}
      />
    </Container>
  );
}

export default Show;
