import { Grid, Text, Stack, Divider, NumberFormatter } from '@mantine/core';
import { CommonHash, Package } from '@types';
import { PACKAGEOPTIONS } from '@constants';

function Packages({ document }: { document: CommonHash }) {
  return (
    <Stack mt={40} gap="0">
      <Grid>
        <Grid.Col span={1} py={0}>
          <Text fw={800} fz={12}>
            Bulto
          </Text>
        </Grid.Col>
        <Grid.Col span={3} py={0}>
          <Text fw={800} fz={12}>
            Tipo
          </Text>
        </Grid.Col>
        <Grid.Col span={1} py={0}>
          <Text fw={800} fz={12}>
            Cantidad
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={0}>
          <Text fw={800} fz={12} ps={10}>
            Marcas
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={0}>
          <Text fw={800} fz={12}>
            Id. Container
          </Text>
        </Grid.Col>
        <Grid.Col span={1} py={0}>
          <Text fw={800} fz={12}>
            Sello
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={0}>
          <Text fw={800} fz={12}>
            Emisor sello
          </Text>
        </Grid.Col>
      </Grid>
      <Divider color="black" my={10} />
      <Grid>
        {document?.transport_attributes?.packages_attributes.map(
          (packageInstance: Package, index: number) => (
            <>
              <Grid.Col span={1} py={1}>
                <Text fz={12}>{index + 1}</Text>
              </Grid.Col>
              <Grid.Col span={3} py={1}>
                <Text fz={12}>
                  {
                    PACKAGEOPTIONS.find((item) => item.value === String(packageInstance.code))
                      ?.label
                  }
                </Text>
              </Grid.Col>
              <Grid.Col span={1} py={1}>
                <Text fz={12}>
                  <NumberFormatter
                    value={packageInstance.quantity}
                    decimalSeparator=","
                    thousandSeparator="."
                  />
                </Text>
              </Grid.Col>
              <Grid.Col span={2} py={1}>
                <Text fz={12} ps={10}>
                  {packageInstance.brand}
                </Text>
              </Grid.Col>
              <Grid.Col span={2} py={1}>
                <Text fz={12}>{packageInstance.container_id}</Text>
              </Grid.Col>
              <Grid.Col span={1} py={1}>
                <Text fz={12}>{packageInstance.seal}</Text>
              </Grid.Col>
              <Grid.Col span={2} py={1}>
                <Text fz={12}>{packageInstance.issuer_seal}</Text>
              </Grid.Col>
            </>
          ),
        )}
      </Grid>
    </Stack>
  );
}

export default Packages;
