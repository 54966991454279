import { Dispatch } from 'react';
import { Combobox, Text, useCombobox, ActionIcon, Group, Checkbox } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconSettings } from '@tabler/icons-react';
import { CommonHash } from '@types';

interface LineSettingsProps {
  settings: CommonHash[];
  setSettings: Dispatch<React.SetStateAction<CommonHash[]>>;
  form: UseFormReturnType<any>;
  lineIndex: number;
}

export default function LineSettings({
  settings,
  setSettings,
  form,
  lineIndex,
}: LineSettingsProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const handleValueSelect = (label: string) => {
    if (settings.find((setting) => setting.label === label)?.value === true) {
      cleanFormValue(label);
    }
    setSettings((currentSettings) =>
      currentSettings.map((setting) =>
        setting.label === label ? { ...setting, value: !setting.value } : setting,
      ),
    );
  };

  const cleanFormValue = (label: string) => {
    const parseLabel = () => {
      switch (label) {
        case 'Descripción':
          return 'description';
        case 'Indicador Facturación':
          return 'billing_indicator';
        case 'Cód. Producto':
          return 'code';
      }
    };
    if (parseLabel() === 'billing_indicator') {
      form.setFieldValue(`document.lines_attributes.${lineIndex}.${parseLabel()}`, 0);
    } else {
      form.setFieldValue(`document.lines_attributes.${lineIndex}.${parseLabel()}`, '');
    }
  };

  const options = settings.map((setting, index) => (
    <Combobox.Option
      value={setting.label}
      key={`${index}}`}
      active={setting.value}
      // onMouseOver={() => combobox.resetSelectedOption()}
    >
      <Group gap="sm">
        <Checkbox
          checked={setting.value}
          aria-hidden
          tabIndex={-1}
          style={{ pointerEvents: 'none' }}
        />
        <span>{setting.label}</span>
      </Group>
    </Combobox.Option>
  ));

  return (
    <>
      <Combobox
        store={combobox}
        width={250}
        position="bottom-start"
        withArrow
        withinPortal={false}
        onOptionSubmit={handleValueSelect}
      >
        <Combobox.Target>
          <ActionIcon
            variant="filled"
            aria-label="Settings"
            onClick={() => combobox.toggleDropdown()}
          >
            <IconSettings style={{ width: '70%', height: '70%' }} stroke={1.5} />
          </ActionIcon>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            <Combobox.Header>
              <Text fz="xs">Agregar opciones a item</Text>
            </Combobox.Header>
            {options}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>

      {/* <Box mt="xs">
        <Text span size="sm" c="dimmed">
          Selected item:{' '}
        </Text>

        <Text span size="sm">
          {selectedItem || 'Nothing selected'}
        </Text>
      </Box> */}
    </>
  );
}
