import { Grid, Text, Stack, Divider, NumberFormatter } from '@mantine/core';
import { Document, Package } from '@types';
import { PACKAGEOPTIONS } from '@constants';

function Packages({ document }: { document: Document }) {
  return (
    <Stack mt={40} gap="0">
      <Grid>
        <Grid.Col span={1} py={0}>
          <Text fw={800} fz={9}>
            Bulto
          </Text>
        </Grid.Col>
        <Grid.Col span={3} py={0}>
          <Text fw={800} fz={9}>
            Tipo
          </Text>
        </Grid.Col>
        <Grid.Col span={1} py={0}>
          <Text fw={800} fz={9}>
            Cantidad
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={0}>
          <Text fw={800} fz={9}>
            Marcas
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={0}>
          <Text fw={800} fz={9}>
            Id. Container
          </Text>
        </Grid.Col>
        <Grid.Col span={1} py={0}>
          <Text fw={800} fz={9}>
            Sello
          </Text>
        </Grid.Col>
        <Grid.Col span={2} py={0}>
          <Text fw={800} fz={9}>
            Emisor sello
          </Text>
        </Grid.Col>
      </Grid>
      <Divider color="black" my={10} />
      <Grid>
        {document?.transport?.packages.map((packageInstance: Package, index: number) => (
          <>
            <Grid.Col span={1} py={1}>
              <Text fz={9}>{index + 1}</Text>
            </Grid.Col>
            <Grid.Col span={3} py={1}>
              <Text fz={9}>
                {PACKAGEOPTIONS.find((item) => String(item.value === packageInstance.code))?.label}
              </Text>
            </Grid.Col>
            <Grid.Col span={1} py={1}>
              <Text fz={9}>
                <NumberFormatter
                  value={packageInstance.quantity}
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </Text>
            </Grid.Col>
            <Grid.Col span={2} py={1}>
              <Text fz={9}>{packageInstance.brand}</Text>
            </Grid.Col>
            <Grid.Col span={2} py={1}>
              <Text fz={9}>{packageInstance.container_id}</Text>
            </Grid.Col>
            <Grid.Col span={1} py={1}>
              <Text fz={9}>{packageInstance.seal}</Text>
            </Grid.Col>
            <Grid.Col span={2} py={1}>
              <Text fz={9}>{packageInstance.issuer_seal}</Text>
            </Grid.Col>
          </>
        ))}
      </Grid>
    </Stack>
  );
}

export default Packages;
