import {
  Container,
  Anchor,
  rem,
  Text,
  Group,
  Stack,
} from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import { useAuth } from '@providers/auth';
import { useState } from 'react';
import EditBankDetailsForm from '@components/BankingDetailsForm/EditBankDetailsForm';
import ShowBankingDetails from '@components/BankingDetailsForm/showBankingDetails';

function Index() {

  const { session } = useAuth();
  const account = session.activeAccount;
  const banking_details = account?.banking_details;
  const [isEditing, setIsEditing] = useState(false);


  return (
    <Container fluid>
      <Group>
        <Anchor
          href="/configuracion"
          underline="never"
          c="dimmed"
          className="d-flex align-items-center"
          fw={700}
        >
          <IconChevronLeft
            style={{
              width: rem(20),
              height: rem(20),
              color: 'var(--mantine-color-dimmed)',
              marginRight: 10,
            }}
          />
          Configuración
        </Anchor>
      </Group>
      <Text fz={28} fw={700}>
        Información bancaria
      </Text>
      <Text fz={14} c="dimmed" mt={5}>
        Los datos bancarios proporcionados serán incluidos en el correo electrónico
        que se enviará a las direcciones de correo de contacto asociadas al documento emitido.
      </Text>
      <Stack align="center" mt={35}>
        {banking_details ? (
          !isEditing ? (
            <ShowBankingDetails
              banking_details={banking_details}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />) : (
            <EditBankDetailsForm
              account={account}
              initialDetails={banking_details}
              onCancel={() => setIsEditing(false)}
            />
          )
        ) : (
          <EditBankDetailsForm
            account={account}
            onCancel={() => setIsEditing(false)}
          />)}
      </Stack>
    </Container>
  );
}



export default Index;
