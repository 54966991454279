import dayjs from 'dayjs';
import { Account, CommonHash, Reference, Charge, Line } from '@types';
import { UseFormReturnType } from '@mantine/form';

export function buildInitialValues(type: string, document?: any, emitter?: Account) {
  const schema: CommonHash = {};
  buildGeneral(schema, type, document);
  buildEmitter(schema, document, emitter);
  buildReceiver(schema, document);
  buildLines(schema, document);
  buildTransport(schema, document);
  buildReferences(schema, document);
  buildCharges(schema, document);
  return schema;
}

// export function buildReferencedDocument(prevValues: CommonHash, document: any) {
//   const schema = prevValues;
//   // console.log('schema 1', schema);
//   buildGeneralReferencedDocument(schema, document);
//   buildReceiver(schema, document);
//   buildLines(schema, document);
//   buildTransport(schema, document);
//   // buildReferenceOfReferencedDocument(schema, document);
//   // console.log('schema 2', schema);
//   return schema;
// }

function buildGeneral(schema: CommonHash, type: string, document?: any) {
  let otherCurrency = '';
  if (exportType(mapApiTypeToFront(document?.type))) {
    otherCurrency = document?.other_currency || 'PESO CL';
  }
  schema['document'] = {
    id: document?.id || '',
    payment_type: document?.payment_type || '',
    issue_date: dayjs(document?.issue_date).toDate() || '',
    kind_of_sale: document?.kind_of_sale || '',
    type: mapApiTypeToFront(type) || 'ElectronicInvoice',
    withholding_tax_percentage: parseFloat(document?.withholding_tax_percentage || 0),
    contact_email: document?.contact_email || [],
    service_indicator: document?.service_indicator || '',
    exportation_payment_type: document?.exportation_payment_type || '',
    currency: document?.currency || '',
    other_currency: otherCurrency,
    exchange_rate: document?.exchange_rate || '',
    nationality: document?.nationality || '',
    company_additional_info: document?.company_additional_info || '',
    dispatch_type: document?.dispatch_type || '',
    transfer_index: document?.transfer_index || '',
    transfer_code: document?.transfer_code || '',
    global_discounts_attributes:
      document?.global_discounts.length > 0
        ? document?.global_discounts
        : [{ kind: 'percentage', value: '', _destroy: false, category: 'discount' }],
  };
}

// function buildGeneralReferencedDocument(schema: CommonHash, document: any) {
//   let otherCurrency = '';
//   if (exportType(mapApiTypeToFront(document?.type))) {
//     otherCurrency = document?.other_currency || 'PESO CL';
//   }
//   schema['document'] = {
//     payment_type: document?.payment_type || '',
//     kind_of_sale: document?.kind_of_sale || '',
//     issue_date: dayjs(schema?.issue_date).toDate() || '',
//     type: mapApiTypeToFront(schema.type) || 'ElectronicInvoice',
//     withholding_tax_percentage: document?.withholding_tax_percentage || 0,
//     contact_email: document?.contact_email || [],
//     service_indicator: document?.service_indicator || '',
//     exportation_payment_type: document?.exportation_payment_type || '',
//     currency: document?.currency || '',
//     other_currency: otherCurrency,
//     exchange_rate: document?.exchange_rate || '',
//     nationality: document?.nationality || '',
//     company_additional_info: document?.company_additional_info || '',
//     global_discounts_attributes:
//       document?.global_discounts.length > 0
//         ? document?.global_discounts
//         : [{ kind: 'percentage', value: '0', _destroy: false }],
//   };
// }
function buildEmitter(schema: CommonHash, document: any, emitter?: Account) {
  schema['account'] = {
    account_activity: document?.account_activity.sii_code || emitter?.activities[0].sii_code,
    account_address: document?.account_location.address || emitter?.locations[0].address,
  };
}

function buildReceiver(schema: CommonHash, document: any) {
  const company = document?.company;
  schema['company'] = {
    tax_id: company?.tax_id || '',
    legal_name: company?.legal_name || '',
    activity: document?.company_activity || '',
    address: document?.company_location?.address || '',
    commune: document?.company_location?.commune || '',
  };
}

function buildLines(schema: CommonHash, document: any) {
  schema.document = {
    ...schema['document'],
    lines_attributes:
      document?.lines.length > 0
        ? document?.lines
        : [
            {
              id: '',
              code: '',
              code_type: '',
              name: '',
              description: '',
              quantity: '',
              billing_indicator: 0,
              amount: '',
              discount: '',
              total_amount: '',
              reference_kind: '',
              _destroy: false,
            },
          ],
  };
}
function buildReferences(schema: CommonHash, document: any) {
  schema.document = {
    ...schema['document'],
    references_attributes: handleReferences(document?.references || []),
  };
}
// function buildReferenceOfReferencedDocument(schema: CommonHash, document: any) {
//   schema.document = {
//     ...schema['document'],
//     references_attributes: [
//       {
//         id: '',
//         code: '',
//         description: '',
//         original_dte_type: document?.type,
//         original_number: document?.number,
//         date: dayjs(document?.issue_date).toDate(),
//         global_index: false,
//         _destroy: false,
//       },
//     ],
//   };
// }

function buildTransport(schema: CommonHash, document: any) {
  const transport = document?.transport;
  schema.document = {
    ...schema['document'],
    transport_attributes: {
      id: transport?.id || '',
      visible: transport !== undefined ? true : false,
      license_plate: transport?.license_plate || '',
      driver_company_tax_id: transport?.driver_company_tax_id || '',
      driver_tax_id: transport?.driver_tax_id || '',
      driver_name: transport?.driver_name || '',
      address: transport?.address || '',
      commune: transport?.commune || '',
      city: transport?.city || '',
      exportation_sales_mode: transport?.exportation_sales_mode || '',
      exportation_sales_clause: transport?.exportation_sales_clause || '',
      exportation_sales_clause_amount: transport?.exportation_sales_clause_amount || '',
      transport_route: transport?.transport_route || '',
      carrier_vessel: transport?.carrier_vessel || '',
      carrier_company_tax_id: transport?.carrier_company_tax_id || '',
      carrier_company_name: transport?.carrier_company_name || '',
      carrier_company_additional_info: transport?.carrier_company_additional_info || '',
      booking_number: transport?.booking_number || '',
      operator_code: transport?.operator_code || '',
      shipping_port_id: transport?.shipping_port_id || '',
      shipping_port_additional_info: transport?.shipping_port_additional_info || '',
      landing_port_id: transport?.landing_port_id || '',
      landing_port_additional_info: transport?.landing_port_additional_info || '',
      tare: transport?.tare || '',
      tare_unit: transport?.tare_unit || '',
      gross_weight: transport?.gross_weight || '',
      gross_weight_unit: transport?.gross_weight_unit || '',
      net_weight: transport?.net_weight || '',
      net_weight_unit: transport?.net_weight_unit || '',
      total_items: transport?.total_items || '',
      total_packages: transport?.total_packages || '',
      freight_amount: transport?.freight_amount || '',
      insurance_amount: transport?.insurance_amount || '',
      receiver_country_id: transport?.receiver_country_id || '',
      destination_country_id: transport?.destination_country_id || '',
      packages_attributes: transport?.packages.length > 0 ? transport?.packages : [],
    },
  };
}

function buildCharges(schema: CommonHash, document: any) {
  const charges = document?.charges;
  schema.document = {
    ...schema['document'],
    charges_attributes: handleCharges(charges || []),
  };
}

function handleReferences(references: Reference[]) {
  const ref = references.map((r) => ({
    ...r,
    id: r.id || '',
    _destroy: false,
    date: dayjs(r.date).toDate(),
  }));
  return ref;
}

function handleCharges(charges: Charge[]) {
  const charge = charges.map((c) => ({
    ...c,
    id: c.id || '',
    _destroy: false,
    kind: c.kind || '',
    description: c.description || '',
    charge: c.charge || '',
    net_amount: c.net_amount || '',
    exempt_amount: c.exempt_amount || '',
    tax_amount: c.tax_amount || '',
  }));
  return charge;
}

export function needReceiver(dteType: string) {
  return !['ElectronicReceipt', 'ElectronicExemptReceipt'].includes(dteType);
}

export function receiptType(dteType: string) {
  return ['ElectronicReceipt', 'ElectronicExemptReceipt'].includes(dteType);
}

export function regularNoteType(dteType: string) {
  return ['ElectronicCreditNote', 'ElectronicDebitNote'].includes(dteType);
}
export function noteType(dteType: string) {
  return [
    'ElectronicCreditNote',
    'ElectronicDebitNote',
    'ElectronicExportCreditNote',
    'ElectronicExportDebitNote',
  ].includes(dteType);
}
export function exemptType(dteType: string) {
  return ['ElectronicExemptInvoice', 'ElectronicExemptReceipt'].includes(dteType);
}

export function exportType(dteType: string) {
  return [
    'ElectronicExportInvoice',
    'ElectronicExportCreditNote',
    'ElectronicExportDebitNote',
  ].includes(dteType);
}

export function purchaseType(dteType: string) {
  return ['ElectronicPurchaseInvoice'].includes(dteType);
}

export function calculateTotals(form: UseFormReturnType<any>) {
  let preNetAmount = 0;
  let preExemptAmount = 0;
  let preTotalAmount = 0;
  let netAmount: number;
  let exemptAmount = 0;
  let withHoldingTaxAmount = 0;
  let taxAmount: number;
  let chargesNetAmount = 0;
  let chargesExemptAmount = 0;
  let chargesTaxAmount = 0;
  // const notBillableAmount = 0;
  let totalAmount: number;
  let globalDiscountAmount = 0;
  if (form.values.document.charges_attributes.length > 0) {
    chargesNetAmount = form.values.document.charges_attributes.reduce(
      (total: number, charge: CommonHash) => {
        return total + parseInt(charge.net_amount || 0);
      },
      0,
    );
    chargesExemptAmount = form.values.document.charges_attributes.reduce(
      (total: number, charge: CommonHash) => {
        return total + parseInt(charge.exempt_amount || 0);
      },
      0,
    );
    chargesTaxAmount = Math.round(chargesNetAmount * 1.19 - chargesNetAmount);
    // notBillableAmount = form.values.document.charges_attributes.reduce(
    //   (total: number, charge: CommonHash) => {
    //     return total + parseInt(charge.charge || 0);
    //   },
    //   0,
    // );
  }
  if (exemptType(form.values.document.type) || exportType(form.values.document.type)) {
    netAmount = 0;
    taxAmount = 0;
    exemptAmount = form.values.document.lines_attributes.reduce(
      (total: number, line: CommonHash) => {
        return total + parseInt(line.total_amount || 0);
      },
      0,
    );
    totalAmount = exemptAmount;
  } else if (receiptType(form.values.document.type)) {
    preTotalAmount = form.values.document.lines_attributes.reduce(
      (total: number, line: CommonHash) => {
        return total + parseInt(line.total_amount || 0);
      },
      0,
    );
    if (globalDiscountPresent(form)) {
      const globalDiscountFloat = form.values.document.global_discounts_attributes[0].value;
      totalAmount = preTotalAmount - Math.round((preTotalAmount * globalDiscountFloat) / 100);
      globalDiscountAmount = preTotalAmount - totalAmount;
    } else {
      totalAmount = preTotalAmount;
    }
    netAmount = Math.round(totalAmount / 1.19);
    taxAmount = totalAmount - netAmount;
  } else {
    preNetAmount = form.values.document.lines_attributes
      .filter((line: Line) => line.billing_indicator === 0)
      .reduce((total: number, line: CommonHash) => {
        return total + parseInt(line.total_amount || 0);
      }, 0);
    preExemptAmount = form.values.document.lines_attributes
      .filter((line: Line) => line.billing_indicator === 1)
      .reduce((total: number, line: CommonHash) => {
        return total + parseInt(line.total_amount || 0);
      }, 0);
    if (globalDiscountPresent(form)) {
      const globalDiscountFloat = form.values.document.global_discounts_attributes[0].value;

      netAmount = preNetAmount - Math.round((preNetAmount * globalDiscountFloat) / 100);
      exemptAmount = preExemptAmount - Math.round((preExemptAmount * globalDiscountFloat) / 100);
      globalDiscountAmount = preNetAmount - netAmount + preExemptAmount - exemptAmount;
    } else {
      netAmount = preNetAmount;
      exemptAmount = preExemptAmount;
    }
    taxAmount = Math.round(netAmount * 1.19 - netAmount);
    if (form.values.document.withholding_tax_percentage !== 0)
      withHoldingTaxAmount = Math.round(netAmount * 1.19 - netAmount); // tengo harcodeado que el impuesto retenido es de un 19%
    totalAmount =
      netAmount +
      exemptAmount +
      taxAmount -
      withHoldingTaxAmount -
      chargesNetAmount -
      chargesTaxAmount -
      chargesExemptAmount;
  }
  return {
    preNetAmount,
    preTotalAmount,
    netAmount,
    exemptAmount,
    taxAmount,
    totalAmount,
    globalDiscountAmount,
    chargesNetAmount,
    chargesExemptAmount,
    chargesTaxAmount,
  };
}

export function transformDTETypeForLabel(dteType: string) {
  if (dteType == 'ElectronicInvoice') return 'FACTURA ELECTRÓNICA';
  if (dteType == 'ElectronicExemptInvoice') return 'FACTURA EXENTA ELECTRÓNICA';
  if (dteType == 'ElectronicReceipt') return 'BOLETA ELECTRÓNICA';
  if (dteType == 'ElectronicExemptReceipt') return 'BOLETA EXENTA ELECTRÓNICA';
  if (dteType == 'ElectronicCreditNote') return 'NOTA DE CRÉDITO ELECTRÓNICA';
  if (dteType == 'ElectronicDebitNote') return 'NOTA DE DÉBITO ELECTRÓNICA';
  if (dteType == 'ElectronicPurchaseInvoice') return 'FACTURA DE COMPRA ELECTRÓNICA';
  if (dteType == 'ElectronicDispatchGuide') return 'GUIA DE DESPACHO ELECTRÓNICA';
  if (dteType == 'ElectronicSettlement') return 'LIQUIDACIÓN FACTURA ELECTRÓNICA';
  if (dteType == 'ElectronicExportInvoice') return 'FACTURA DE EXPORTACIÓN ELECTRÓNICA';
  if (dteType == 'ElectronicExportCreditNote') return 'NOTA DE CRÉDITO DE EXPORTACIÓN ELECTRÓNICA';
  if (dteType == 'ElectronicExportDebitNote') return 'NOTA DE DÉBITO DE EXPORTACIÓN ELECTRÓNICA';
}

function globalDiscountPresent(form: UseFormReturnType<any>) {
  return form.values.document.global_discounts_attributes[0].value != '';
}

export function calculateLineTotal(
  preTransformAmount: number,
  preTransformQuantity: number,
  preTransformDiscount: number,
) {
  const amount = preTransformAmount;
  const quantity = preTransformQuantity;
  const discount = (preTransformDiscount || 0) / 100;
  return Math.round(quantity * amount * (1 - discount));
}

export function calculateLineDiscount(amount: number, quantity: number, discount: number) {
  const total = amount * quantity;
  const discountDecimal = (discount || 0) / 100;

  return Math.round(total - Math.round(total * (1 - discountDecimal)));
}
export function transformCommaToDot(value: string) {
  return value.replace(/,/g, '.');
}

export function checkPurchaseInvoiceReference(references: CommonHash[]) {
  return references.some((ref: CommonHash) => ref.original_dte_type === '46');
}

function mapApiTypeToFront(type: string): string {
  const types: Record<string, string> = {
    'Documents::ElectronicInvoice': 'ElectronicInvoice',
    'Documents::ElectronicExemptInvoice': 'ElectronicExemptInvoice',
    'Documents::ElectronicReceipt': 'ElectronicReceipt',
    'Documents::ElectronicExemptReceipt': 'ElectronicExemptReceipt',
    'Documents::ElectronicCreditNote': 'ElectronicCreditNote',
    'Documents::ElectroniDebitNote': 'ElectronicDebitNote',
    'Documents::ElectronicPurchaseInvoice': 'ElectronicPurchaseInvoice',
    'Documents::ElectronicDispatchGuide': 'ElectronicDispatchGuide',
    'Documents::ElectronicSettlement': 'ElectronicSettlement',
    'Documents::ElectronicExportInvoice': 'ElectronicExportInvoice',
    'Documents::ElectronicExportCreditNote': 'ElectronicExportCreditNote',
    'Documents::ElectronicExportDebitNote': 'ElectronicExportDebitNote',
    ElectronicInvoice: 'ElectronicInvoice',
    ElectronicExemptInvoice: 'ElectronicExemptInvoice',
    ElectronicReceipt: 'ElectronicReceipt',
    ElectronicExemptReceipt: 'ElectronicExemptReceipt',
    ElectronicCreditNote: 'ElectronicCreditNote',
    ElectronicDebitNote: 'ElectronicDebitNote',
    ElectronicPurchaseInvoice: 'ElectronicPurchaseInvoice',
    ElectronicDispatchGuide: 'ElectronicDispatchGuide',
    ElectronicSettlement: 'ElectronicSettlement',
    ElectronicExportInvoice: 'ElectronicExportInvoice',
    ElectronicExportCreditNote: 'ElectronicExportCreditNote',
    ElectronicExportDebitNote: 'ElectronicExportDebitNote',
  };
  return types[type];
}
