import request from '@utils/axios-utils';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ApiObject, Account, ApiList, CommonHash } from '@types';
import { useAuth } from '@providers/auth';
import Notification from '@components/notification/notification';
import { useNavigate } from 'react-router-dom';

export function useUserActiveAccount() {
  const { session } = useAuth();
  return useQuery<ApiObject<Account>>(
    ['account'],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/users/active_account`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}

export function useUserAccounts() {
  const { session } = useAuth();
  return useQuery<ApiList<Account>>(
    ['accounts'],
    async () => {
      const response = await request({
        authorizationToken: session?.token,
        url: `/users/accounts`,
        method: 'GET',
      });
      return response.data;
    },
    {
      enabled: session.status != 'loading',
    },
  );
}

export function useUpdateUserActiveAccount() {
  const queryClient = useQueryClient();
  const { session, setSession } = useAuth();
  return useMutation(
    ({ data }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/users/change_active_account`,
        method: 'PATCH',
        data,
      });
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries();
        setSession({ activeAccount: data.data });
      },
    },
  );
}

export function useForgottenUserPassword() {
  const navigate = useNavigate();
  return useMutation(
    ({ email }: CommonHash) => {
      return request({
        url: `/users/password_recovery`,
        method: 'POST',
        data: { email },
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Revisa tu correo para recuperar tu contraseña' });
        navigate('/login');
      },
      onError: (error: any) => {
        Notification({ type: 'error', message: error?.response?.data?.error });
      },
    },
  );
}

export function useChangeUserPasswordByRecovery() {
  const navigate = useNavigate();
  return useMutation(
    ({ password, token }: CommonHash) => {
      return request({
        url: `/users/change_password`,
        method: 'PATCH',
        data: { password, token },
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Contraseña cambiada correctamente' });
        navigate('/login');
      },
      onError: (error: any) => {
        Notification({ type: 'error', message: error?.response?.data?.error });
      },
    },
  );
}

export function useNewUserPassword() {
  const navigate = useNavigate();
  return useMutation(
    ({ password, token }: CommonHash) => {
      return request({
        url: `/users/new_user`,
        method: 'PATCH',
        data: { password, token },
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Contraseña creada correctamente' });
        navigate('/login');
      },
      onError: (error: any) => {
        Notification({ type: 'error', message: error?.response?.data?.error });
      },
    },
  );
}

export function useUpdatePassword() {
  const { session } = useAuth();
  const navigate = useNavigate();
  return useMutation(
    ({ password, new_password }: CommonHash) => {
      return request({
        authorizationToken: session?.token,
        url: `/users/update_password`,
        method: 'PATCH',
        data: { password, new_password },
      });
    },
    {
      onSuccess: () => {
        Notification({ type: 'success', message: 'Contraseña cambiada correctamente' });
        navigate('/');
      },
      onError: (error: any) => {
        Notification({ type: 'error', message: error?.response?.data?.error });
      },
    },
  );
}
