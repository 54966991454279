import { useForm, matchesField } from '@mantine/form';
import { Button, PasswordInput, Paper, Text } from '@mantine/core';
import { useUpdatePassword } from '@api/users';
import styles from './Users.module.scss';
export default function ChangePassword() {
  const { mutate: updatePassword } = useUpdatePassword();
  const form = useForm({
    initialValues: { password: '', new_password: '', confirm_password: '' },
    validate: {
      password: (value: string) => (value.length < 2 ? 'Debes ingresar una contraseña' : null),
      new_password: (value: string) => (value.length < 2 ? 'Debes ingresar una contraseña' : null),
      confirm_password: matchesField('new_password', 'Las contraseñas no coinciden'),
    },
  });

  const handleSubmit = (e: any) => {
    updatePassword(e);
  };
  return (
    <div className={styles.container}>
      <Paper shadow="sm" maw={400} p="md" className={styles.paper}>
        <Text fw={700} size="xl" mt="md" mb="lg">
          Cambiar contraseña
        </Text>
        <form onSubmit={form.onSubmit(handleSubmit)} className={styles.form}>
          <PasswordInput
            label="Contraseña actual"
            placeholder="Contraseña actual"
            size="md"
            {...form.getInputProps('password')}
          />
          <PasswordInput
            label="Nueva contraseña"
            placeholder="Nueva contraseña"
            size="md"
            {...form.getInputProps('new_password')}
          />
          <PasswordInput
            label="Confirma la nueva contraseña"
            placeholder="Confirma la nueva contraseña"
            size="md"
            {...form.getInputProps('confirm_password')}
          />
          <Button type="submit" fullWidth mt="xl" size="md">
            Cambiar contraseña
          </Button>
        </form>
      </Paper>
    </div>
  );
}
