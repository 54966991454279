import { Paper, Button } from '@mantine/core';
import { IconFilter } from '@tabler/icons-react';
import { useFilters } from '@providers/filters';

export default function FilterButton({ open }: { open: () => void }) {
  const { filtersCounter } = useFilters();

  return (
    <Paper shadow="xs">
      <Button onClick={open} leftSection={<IconFilter size={14} />}>
        Filtros {filtersCounter > 0 && `(${filtersCounter})`}
      </Button>
    </Paper>
  );
}
