import { Modal, Stack, Text, List } from '@mantine/core';
import { CommonHash } from '@types';

interface CompProps {
  opened: boolean;
  close: () => void;
  errors: CommonHash;
}

function ErrorModal({ opened, close, errors }: CompProps) {
  return (
    <Modal.Root size="lg" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Text fz={20} fw={600}>
              Errores
            </Text>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack justify="center" align="center" px={30} pb={10}>
            <List>
              {Object.entries(errors || {}).map(([key, value]) => {
                return (
                  <List.Item key={key}>
                    <Text fw={700}>Linea {key}:</Text>
                    <List withPadding listStyleType="disc">
                      {value.map((error: string, index: number) => (
                        <List.Item key={`${key}-${index}`} maw={450}>
                          {error}
                        </List.Item>
                      ))}
                    </List>{' '}
                  </List.Item>
                );
              })}
            </List>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}

export default ErrorModal;
