import { Grid, Text } from '@mantine/core';
import { useAuth } from '@providers/auth';
import styles from './ModalPrevisualization.module.scss';
import { Document } from '@types';
import { transformDTETypeForLabel } from '@pages/authenticated/documents/form/formUtils';

function Header({ document }: { document: Document }) {
  const { session } = useAuth();
  const account = session.activeAccount;
  const logoUrl = session.activeAccount?.logo;
  return (
    <Grid>
      <Grid.Col span={4} className={styles.col}>
        {logoUrl && (
          <>
            <img src={logoUrl} alt="logo" className={styles.logoContainer} />
            <div></div>
          </>
        )}
      </Grid.Col>
      <Grid.Col span={4} className={styles.col}>
        <Text fw={800}>{account?.legal_name}</Text>
        <Text fz={11} truncate="end">
          {document?.account_activity.name || ''}
        </Text>
        <Text fz={11}>{document?.account_location.address}</Text>
      </Grid.Col>
      <Grid.Col span={4} className={styles.col}>
        <div className={styles.redContainer}>
          <Text fw={400} fz={11}>
            Rut {account?.tax_id}
          </Text>
          <Text fw={700} fz={11}>
            {transformDTETypeForLabel(document?.type.split('::')[1] || 'ElectronicInvoice')}
          </Text>
          <Text fz={11}>N° folio no asignado</Text>
        </div>
      </Grid.Col>
    </Grid>
  );
}

export default Header;
